import { Buffer } from 'buffer';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './normalize.css';
import './index.css';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider, MuiPickersAdapterContext } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en';

   window.Buffer = Buffer;

   const initYandexMetrica = () => {
    // Замените на ваш ID счетчика
    window.ym = function () {
        (window.ym.a = window.ym.a || []).push(arguments);
    };
    window.ym.l = 1 * new Date();
    window.ym(99026287, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true, // Включите вебвизор
    });
};

const YandexMetrica = () => {
    React.useEffect(() => {
        // Динамическое подключение Яндекс.Метрики
        const script = document.createElement('script');
        script.src = `https://mc.yandex.ru/metrika/tag.js`;
        script.async = true;
        script.onload = initYandexMetrica;
        document.head.appendChild(script);
    }, []);

    return null;
};



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <TonConnectUIProvider manifestUrl="https://lidum.ru/tonconnect-manifest.json">
   <BrowserRouter>
   <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
   <YandexMetrica />
    <App />
   </LocalizationProvider>
    
    </BrowserRouter>
    </TonConnectUIProvider>
  </React.StrictMode>
);
 
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
