import '../Header.css';
import Switch from "react-switch";
import PopupMenu from './PopupMenu';


const SwitchToggle = ({ address, onDisconnect, handleConnect, switchDisabled, setIsCopyPopupOpen }) => {
    var checked = address ? true : false
    var label = address ? `${address.slice(0, 4)}...${address.slice(-3)}` : "wallet"
  
    const handleOnChange = () => {
        checked = address ? true : false
        label = address ? `${address.slice(0, 4)}...${address.slice(-3)}` : "wallet"
    }

    return (
       
        //disconnect-menu-disabled
        <label className={`react-switch-container no-select ${checked ? "react-switch-on" : "react-switch-off"} ${switchDisabled ? "disconnect-menu-disabled" : ""}`} onClick={handleConnect}>
        <Switch onChange={handleOnChange} className='react-switch'
            checked={checked} 
            disabled 
            uncheckedIcon={false} 
            checkedIcon={false}
            handleDiameter={5}
            height={25} 
            width={35}
            onColor="transparent"
            offColor="transparent"
            onHandleColor="#fff"
            offHandleColor="#449BF1" />
        
        
        <PopupMenu address={address} label={label} onDisconnect={onDisconnect} active={checked && !switchDisabled} switchDisabled={switchDisabled} setIsCopyPopupOpen={setIsCopyPopupOpen} />
        </label>
        
    )
}

export default SwitchToggle