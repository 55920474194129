import { useEffect, useRef, useState } from "react"
import { motion } from 'framer-motion';

export const PopupWindow = ({message, status, addClassName, setStatus}) => {

    const [visible, setVisible] = useState(true)
    const can_be_closed = status === "loading" ? false : true

    const handleClose = () => {
        setVisible(false);
        if (setStatus) {
            setStatus(null)
        }
    }

    useEffect(() => {
       
        if (can_be_closed) {
            const timer = setTimeout(() => {
                setVisible(false);
                if (setStatus) {
                    setStatus(null)
                }
            }, 3000);

           
            return () => clearTimeout(timer);
        }
    }, [can_be_closed, message]); 


    return (
        <>
           {visible && (
            
                <div className={`popup-window ${addClassName ? addClassName : ''}`}>
                    <div className="popup-flex">
                        <p className="popup-message">{message}</p>
                        <p className="popup-close" onClick={handleClose}>x</p>  
                    </div>
                </div>

           )
           } 

        </>
       
    )
}



export const RepeatingPopupWindow = ({message, isOpen, setIsOpen, addClassName}) => {

  
    const handleOverlayClick = (event) => {
        // Проверяем, был ли клик по оверлею
        if (event.target === event.currentTarget) {
            setIsOpen(false)
        }
      };

    const handleClose = () => {
        setIsOpen(false);
    }

    useEffect(() => {
       
        if (isOpen) {
            const timer = setTimeout(() => {
                setIsOpen(false);                
            }, 2000);

           
            return () => clearTimeout(timer);
        }
    }, [isOpen]); 


    return (
        <>

            {isOpen && (
                        
                <div className="modal-overlay" onClick={handleOverlayClick}>
                        <div className="modal-window">
                            <p className="modal-message">{message}</p>
                            <div className="modal-buttons-container">                
                            <button className="modal-button" onClick={handleClose} style={{width: "auto"}}>CANCEL</button>
                            </div>
                        </div>
                        </div>

                )
            }


           {/* {isOpen && (
            
                <div className={`popup-window top-popup-window ${addClassName ? addClassName : ''}`}>
                    <div className="popup-flex">
                        <p className="popup-message">{message}</p>
                        <p className="popup-close" onClick={handleClose}>x</p>  
                    </div>
                </div>

           )
           }  */}

        </>
       
    )
}

export const PopupConnectWallet = ({status}) => {
   
    const [visible, setVisible] = useState(false);
    const [shouldExit, setShouldExit] = useState(false); // Новое состояние для контроля выхода
    const can_be_closed = status === "loading" ? false : true
    useEffect(() => {

        setVisible(true); 
        // Устанавливаем таймер для удержания поп-апа на экране
        if (!can_be_closed) {
            return
        }
        const holdTimer = setTimeout(() => {
            setShouldExit(true); // Задаем флаг выхода
        }, 2000); // Удерживаем 2 секунды

        // Таймер для скрытия поп-апа
        const exitTimer = setTimeout(() => {
            setVisible(false); // Скрываем элемент после выхода
        }, 2500); // 0.5 секунды анимация + 2 сек держим = 2500

        return () => {

            clearTimeout(holdTimer);
            clearTimeout(exitTimer);
        };
    }, [can_be_closed, status]);


    if (!status) {
        return null
    }
    
    
    const message = status === "loading" ? "Your account is connecting..." : status === "error" ? "The wallet is not connected" : "Wallet connected"

    


    return (
        <>
            {visible && (
                <motion.div
                    initial={{ y: -200, opacity: 0 }} // Начальная позиция
                    animate={{ y: shouldExit ? -200 : 0, opacity: 1 }} // Анимация показа
                    exit={{ y: -200, opacity: 0 }} // Уход вверх при скрытии
                    transition={{ duration: 0.5 }} // Время аннимации
                    className={`popup-window wallet-pop-up`} // Классы для стилей
                    style={{ position: 'absolute', top: 0, transform: 'translateX(-50%)', zIndex: 100 }} // Позиционирование
                >
                    <p className="popup-message">{message}</p>
                </motion.div>
            )}
        </>
    )

}

export const PopupMinter = ({status}) => {

    if (!status || status==="success") {
        return null
    }
    
    const message = status === "loading" ? "We are creating your event..." : "Error sending data! Please, try again later"
    return (
        <>
            <PopupWindow message={message} status={status} />
        </>
    )

}

export const PopupTransactionFailed = ({status}) => {

    if (status !== "error") {
        return null
    }
    
    const message = "Transaction failed. Please, try again"
    return (
        <>
            <PopupWindow message={message} status={status} />
        </>
    )

}

export const PopupTransactionProcess = ({status, setStatus}) => {

    if (!status || status==="success") {
        return null
    }
    const message = status === "loading" ? "Transaction in progress..." : "There was an error processing the transaction. Please try again"
    return (
        <>
            <PopupWindow message={message} status={status} setStatus={setStatus} />
        </>
    )

}

export const PopupFormErrorMessage = ({message, setStatus}) => {
    if (!message) {
        return null
    }

    return (
        <>
            <PopupWindow message={message} status={"error"} setStatus={setStatus} />
        </>
    )
}

export const PopupErrorMessage = ({message, setStatus}) => {
    if (!message) {
        return null
    }

    return (
        <>
            <PopupWindow message={message} status={"error"} setStatus={setStatus} />
        </>
    )
}

/* <PopupReceivedError status={receivedError} visible={showErrorPopup} setVisible={setShowErrorPopup} />  
            <PopupCntError status={cntError} visible={showErrorPopup} setVisible={setShowErrorPopup} />  
            <PopupFormErrorMessage message={formErrorMessage}  setStatus={setFormErrorMessage}  />
            <PopupChecker status={loadingSendNftStatus} setStatus={setLoadingSendNftStatus}  />   
          
            <PopupPasswordError status={passwordError} visible={showErrorPopup} setVisible={setShowErrorPopup} /> */

export const PopupChecker = ({status, setStatus}) => {

    if (!status) {
        return null
    }
    
    var message = "We are sending the NFT..."

    switch (status) {
        case "success":
            message = "Sent"
            break;
        case "error":
            message = "Server Error"
            break; 
        case "NOT_FOUND":
            message = "Event not found"                
            break;
        case "DB_READING_ERROR":
            message = "Server Error"
            break;
        case "DB_WRITING_ERROR":
            message = "Server Error"
            break;
        case "SERVER_ERROR":
            message = "Server Error"
            break;                        
        case "EVENT_NFTS_LEFT":
            message = "All the NFTs from this event have already been distributed"
            break;
        case "REPEAT_USER":
            message = "You have already received the NFT from this event"
            break;

        case "MINT_ERROR":
            message = "Error when trying to mint NFT into a collection"
            break;

        case "NFT_TRANSFER_ERROR":
            message = "Error when trying to send the NFT"
            break;            

        default:
            break;
    }



    
    // const message = status === "loading" ? "We are sending the NFT..." : status === "success" ? "Data sent" : "Error sending data! Please, try again later"
    return (
        <>
        <PopupWindow message={message} status={status} setStatus={setStatus} />
    </>
    )

}

export const PopupError = ({status, visible, setVisible, message}) => {
    if (!status || !visible) {
        return null
    }
    return (
        <>
            <PopupWindow message={message} status={visible} setStatus={setVisible} />
        </>
    )
}

export const PopupCntError = ({status, visible, setVisible}) => {

    const message = status === "distributed" ? "All the NFTs from this event have already been distributed" : "Server error"
    return (
        <>
            <PopupError status={status} message={message} visible={visible} setVisible={setVisible} />
        </>
    )

}

export const PopupReceivedError = ({status, visible, setVisible}) => {

  
    const message = "You have already received the nft from this event"
    return (
        <>
            <PopupError status={status} message={message} visible={visible} setVisible={setVisible} />
        </>
    )

}

export const PopupPasswordError = ({status, visible, setVisible}) => {
  
    const message = status === "wrong" ? "Wrong password" : status === "error" ? "Server Error" : "Password verification error"
    return (
        <>
            <PopupError status={status} message={message} visible={visible} setVisible={setVisible} />
        </>
    )

}


// export const PopupContainer = ({ errors, setErrors }) => {
//     const [visibleErrors, setVisibleErrors] = useState([]);

//     const handlePopupClose = (index) => {
//         setVisibleErrors(prevErrors => prevErrors.filter((_, i) => i !== index));
//         setErrors(prevErrors => prevErrors.filter((_, i) => i !== index));
        
//     }
//     // Добавление ошибок при их изменении
//     useEffect(() => {
//         if (Array.isArray(errors) && errors.length > 0) {
//             // Копируем текущие ошибки в видимые ошибки, если они не загружаются
//             setVisibleErrors(prevErrors => [...prevErrors, ...errors]);
//         }
//     }, [errors]); // Обновляем видимые ошибки при изменении входных ошибок

//     useEffect(() => {
//         const timers = [];

//         // Устанавливаем таймер для каждой ошибки, кроме тех, что загружаются
//         visibleErrors.forEach((error, index) => {
//             if (error.status !== "loading") { // Если ошибка не в статусе "loading"
//                 const timer = setTimeout(() => {
//                     setVisibleErrors(prevErrors => prevErrors.filter((_, i) => i !== index));
//                     setErrors(prevErrors => prevErrors.filter((_, i) => i !== index));
//                 }, 5000); // Время жизни ошибки - 3 секунды
//                 timers.push(timer);
//             }
//         });

//         // Очистка таймеров при размонтировке компонента или обновлении видимых ошибок
//         return () => {
//             timers.forEach(timer => clearTimeout(timer));
//         };
//     }, [visibleErrors]); // Обновляем при изменении видимых ошибок

//     if (!visibleErrors || visibleErrors.length === 0) {
//         return null; // Если нет ошибок, ничего не рендерим
//     }

//     return (
//         <div className="popup-errors-box">
//             {visibleErrors.map((error, index) => (
//                 <PopupWindowNew
//                     key={index}
//                     index={index}
//                     message={error.message}
//                     status={error.status}
//                     addClassName={error.status === "success" ? "popup-window-success" : error.status === "error" ? "popup-window-error" : ""}
//                     handleClose={handlePopupClose}
//                 />
//             ))}
//         </div>
//     );
// };


export const PopupContainer = ({ errors }) => {
    const [visibleErrors, setVisibleErrors] = useState(new Map());

    const handlePopupClose = (key) => {
        setVisibleErrors((prevErrors) => {
            const newErrors = new Map(prevErrors);
            newErrors.delete(key); // Удаляем ошибку по ключу
            return newErrors;
        });
        
    }

     // Обновление видимых ошибок на основе входных ошибок
     useEffect(() => {
        setVisibleErrors((prevVisibleErrors) => {
            const newVisibleErrors = new Map(prevVisibleErrors);

            // Удаляем ключи, которые больше не присутствуют в errors
            newVisibleErrors.forEach((_, key) => {
                if (!errors.has(key)) {
                    newVisibleErrors.delete(key);
                }
            });

            // Добавляем ошибки из `errors`
            errors.forEach((value, key) => {
                newVisibleErrors.set(key, value); // Копируем ошибки из props
            });

            return newVisibleErrors;
        });
    }, [errors]);

    useEffect(() => {
        const timers = [];

        // Устанавливаем таймер для каждой ошибки
        visibleErrors.forEach((error, key) => {
            if (error.status !== "loading") {

                const timer = setTimeout(() => {
                
                    setVisibleErrors((prevErrors) => {
                        const newErrors = new Map(prevErrors);
                        newErrors.delete(key); // Удаляем ошибку по ключу
                        return newErrors;
                    });
                }, 3000); // Таймер установлен на 3 секунды
                timers.push(timer);
            }
           
        });

        // Очистка таймеров при размонтировке компонента или обновлении видимых ошибок
        return () => {
            timers.forEach(timer => clearTimeout(timer));
        };
    }, [visibleErrors]); // Обновляем при изменении видимых ошибок

    if (visibleErrors.size === 0) {
        return null; // Если нет ошибок, ничего не рендерим
    }

    return (
        <div className="popup-errors-box">
            {Array.from(visibleErrors.entries()).map(([key, error]) => ( // Преобразуем Map в массив для мапирования
                <PopupWindowNew
                    key={key}
                    index={key}
                    message={error.message}
                    status={error.status}
                    addClassName={error.status === "success" ? "popup-window-success" : error.status === "error" ? "popup-window-error" : ""}
                    handleClose={handlePopupClose}
                />
            ))}
        </div>
    );
};


export const PopupWindowNew = ({message, addClassName, index, handleClose}) => {

  
    


    return (
        <>
           <div className={`popup-window ${addClassName ? addClassName : ''}` }>
               
                    <div className="popup-flex">
                        <p className="popup-message">{message}</p>
                        <p className="popup-close" onClick={() => handleClose(index)}>x</p>  
                    </div>
                
            </div>

        </>
       
    )
}

export const PopupCopied = ({isOpen, setIsOpen}) => {

   

    useEffect(() => {
        const handleClickOutside = () => {
            setIsOpen(false)
        };

        // Добавляем обработчик события при монтировании компонента
        document.addEventListener('mousedown', handleClickOutside);

        // Удаляем обработчик события при размонтировании компонента
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    console.log("PopupCopied isOpen setIsOpen", isOpen, setIsOpen)
    useEffect(() => {
        
        if (isOpen) {
            const timer = setTimeout(() => {
                setIsOpen(false);
            }, 2000);

           
            return () => clearTimeout(timer);
        }
       
            
        
    }, [isOpen]); 

    if (!isOpen) {
        return
    }
  
    return (
        <>
           <div className={`copy-window` }>
               
                    <div className="copy-window-content">
                        <p className="popup-message">Text copied to clipboard</p>
                    </div>
                
            </div>

        </>
       
    )
}

// export const PopupTransactionProcess = ({status}) => {
   
//     const [visible, setVisible] = useState(false);
//     const [shouldExit, setShouldExit] = useState(false); // Новое состояние для контроля выхода
//     const can_be_closed = status === "loading" ? false : true
//     useEffect(() => {

//         setVisible(true); 
//         // Устанавливаем таймер для удержания поп-апа на экране
//         if (!can_be_closed) {
//             return
//         }
//         const holdTimer = setTimeout(() => {
//             setShouldExit(true); // Задаем флаг выхода
//         }, 2000); // Удерживаем 2 секунды

//         // Таймер для скрытия поп-апа
//         const exitTimer = setTimeout(() => {
//             setVisible(false); // Скрываем элемент после выхода
//         }, 2500); // 0.5 секунды анимация + 2 сек держим = 2500

//         return () => {

//             clearTimeout(holdTimer);
//             clearTimeout(exitTimer);
//         };
//     }, [can_be_closed, status]);


//     if (!status) {
//         return null
//     }
    
    
//     const message = status === "loading" ? "Transaction in progress..." : status === "error" ? "There was an error processing the transaction. Please try again" : "An event is being created..."

    


//     return (
//         <>
//             {visible && (
//                 <motion.div
//                     initial={{ y: -200, opacity: 0 }} // Начальная позиция
//                     animate={{ y: shouldExit ? -200 : 0, opacity: 1 }} // Анимация показа
//                     exit={{ y: -200, opacity: 0 }} // Уход вверх при скрытии
//                     transition={{ duration: 0.5 }} // Время аннимации
//                     className={`popup-window wallet-pop-up`} // Классы для стилей
//                     style={{ position: 'absolute', top: 0, transform: 'translateX(-50%)', zIndex: 100 }} // Позиционирование
//                 >
//                     <p className="popup-message">{message}</p>
//                 </motion.div>
//             )}
//         </>
//     )

// }
//PopupWindow

