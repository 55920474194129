import axios from "../axios";

export const getPrice = async (nfts_cnt) => {
    try {
        const {data} = await axios.post(`/dropper/get_price/`, {
            nfts_cnt: nfts_cnt, 
        })
        if (!data.price) {
            console.log("Error!", data.description)
            return null
        }
        return data.price
    } catch (error) {
        console.log(error)
        return 0.01 + getRandomInt(100);
    }
}



export const createDrop = async (wallet_address, start_date, end_date, prizes, price) => {
    try {
        const {data} = await axios.post(`/dropper/create_drop/`, {
            wallet_address: wallet_address,
            start_date: start_date,
            end_date: end_date,
            prizes: prizes,
            price: price,
        })
        if (!data.drop_id) {
            console.log("Error!", data.description)
            return null
        }
        return data.drop_id
    } catch (error) {
        console.log(error)
        return 0;
    }
}




function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }