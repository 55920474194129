import { TonConnectButton } from "@tonconnect/ui-react"
import twitterLogo from '../img/twitter.svg'
import telegramLogo from '../img/telegram.svg'
import { Link, Navigate, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { PopupConnectWallet } from "../components/General/PopupWindow"
import { LeaveCheckerWindow, MakePostResultWindow } from "../components/General/ModalWindow"

const Home = ({setComingSoonOpen, wallet, setIsBackButtonShow}) => {

   console.log("setComingSoonOpen", setComingSoonOpen)
    useEffect(() => {

        setIsBackButtonShow(false)
        
    }, []);

    
    const navigator = useNavigate();
   
    return (
        
        <div className="home-container no-select">
        
        
         {/* <PopupConnectWallet status={isWalletStatus} walletPopupVisible={walletPopupVisible} setWalletPopupVisible={setWalletPopupVisible} /> */}
         <div className="for-button-click event-btn">
            <div className="event-box no-select" onClick={() => navigator("/minter")}><span className='event-box-text'>New event</span></div>
        </div>
        <div className="buttons-box">
            <div className="for-button-click link-btn grow" onClick={() => setComingSoonOpen(true)}>
                <div className="link-button no-select"><span>Burn</span></div>
            </div>
            <div className="for-button-click link-btn" style={{width: "134px"}}>
                <div className="link-button no-select" onClick={() => navigator("/account")}><span>Account</span></div>
            </div>
        </div>
        <div className="about-button-box">
        <a className="for-button-click about-btn" href="https://teletype.in/@lidum/cJPax1A6Fu9" target="_blank" rel="noopener noreferrer">
                <div className="link-button no-select">About</div>
        </a>
        </div>

        <TonConnectButton className='tonConnectButton' style={wallet ? {display: "none"} : {display: "block"}}/>
        {/* <div className="media-container">
            <Link href="/#" className="our-media">our media</Link>
            <Link href="/#" className="github" rel="noopener noreferrer">GitHub</Link>
            <Link href="https://t.me/LidumTMA" className="icon" target="_blank" rel="noopener noreferrer"><img src={telegramLogo} alt="telegram" /></Link>
            <Link href="/#" className="docs">docs</Link>
        </div> */}
        </div>
        
    )
}

export default Home