import { formattedDatetime } from "../../utils/appUtils"


export const EventPreview = ({logo, collection_name, event_name}) => {


    return (
        <div className="collection_description_container">
            <div className="collection_image_container info">
                <img src={logo} alt="Collection" className="img" id="img" />
            </div>
            <div className="collection_summary">
                <h1 className="collection_title">{collection_name}</h1>
                <div className="collection_description">{event_name}</div>                 
                
            </div>
       
         </div>
    )
}

