import { useRef, useState, useEffect } from "react";
import { addVisitedChannel, IsUserSubscribed } from "../../utils/checkerConnections";

// Компонент для отображения отдельного элемента подписки
const SubscriptionElement = ({ subscription, isActive, avatarUrl, readOnly, handleClick }) => {
    const channel = "https://t.me/" + subscription.substring(1, subscription.length);
   
  

    // const handleClick = () => {
    //     if (isActive || readOnly) {
    //         return
    //     }        
    //     onClick();
    // };
    return (
        <>
        {(!readOnly) && (
           
            <a
            className={`subscription-field ${isActive ? 'active' : ''}`}
             href={channel} target="_blank" rel="noopener noreferrer" onClick={handleClick}
        >
           
            <img className="subscription-field-avatar" width={50} height={50} src={avatarUrl} alt={subscription + " avatar"} />
            <span>
                {subscription}
            </span>
          
        </a>
       
        )
        }

        {readOnly && 

        <div
            className={`subscription-field ${isActive ? 'active' : ''} readonly-input-field`}         
        >
           
            <img className="subscription-field-avatar" src={avatarUrl} alt={subscription + " avatar"} />
            <span>
                {subscription}
            </span>
          
        </div>

        }

        </>
    );
};

// Компонент для отображения списка подписок
export const SubscriptionList = ({ selectedSubscriptions, setSelectedSubscriptions, subscriptions, avatarUrls, telegramId, readOnly, visitedChannels }) => {
    const intervalRefs = useRef([]);
    const [clickSubscriptions, setClickSubscriptions] = useState([])
    // Функция добавления элемента
    const addSubscription = (item) => {
        if (!selectedSubscriptions.includes(item)) {
            setSelectedSubscriptions((prevState) => [...prevState, item]); // Добавляет новый элемент в конец массива
        }
        
    };

    const handleLinkClick = (subscription) => {
        if (clickSubscriptions.includes(subscription) && !selectedSubscriptions.includes(subscription)) {

            setTimeout(() => {
                addSubscription(subscription)     
              }, 5000);
                  
        }
    }

    useEffect(() => {

        for (let idx = 0; idx < subscriptions.length; idx++) {
            if (intervalRefs.current[idx]) {
                clearInterval(intervalRefs.current[idx])
            }
            const subscription = subscriptions[idx];
            intervalRefs.current[idx] = setInterval(async () => {
                console.log(`intervalRefs.current[${idx}]`, intervalRefs.current[idx])
                    const subscribed_data = await IsUserSubscribed(telegramId, subscription)
                    if (subscribed_data && subscribed_data.bot_is_admin && subscribed_data.subscribed) {
                        addSubscription(subscription);
                        console.log(selectedSubscriptions)
                        clearInterval(intervalRefs.current[idx])
                        return
                    } 
                    
                    if (subscribed_data && !subscribed_data.bot_is_admin) {
                        clearInterval(intervalRefs.current[idx]) 
                        if (visitedChannels.includes(subscription)) {
                            addSubscription(subscription)
                            return
                        }  
                        setClickSubscriptions((prevState) => [...prevState, subscription]); 
                    }
              }, 3000);
            
        }


    }, [subscriptions])

    // Обработчик клика
    // const handleClick = async (subscription, index) => {
    //     const idx = parseInt(index, 10);
    //     // addSubscription(subscription);
    //     intervalRefs.current[idx] = setInterval(async () => {
    //         console.log("intervalRefs.current[idx]", intervalRefs.current[idx])
    //             const subscribed_data = await IsUserSubscribed(telegramId, subscription)
    //             if (subscribed_data && subscribed_data.status === "success" && !subscribed_data.bot_is_admin && subscribed_data.subscribed) {
    //                 addSubscription(subscription);
    //                 console.log(selectedSubscriptions)
    //                 clearInterval(intervalRefs.current[idx])
    //             } 
    //       }, 3000);
     
    // };

    
    return (
        <div style={{marginBottom: "27px"}}>
        
            {subscriptions.map((subscription, index) => (
                        <SubscriptionElement
                            readOnly={readOnly}
                            key={subscription}
                            subscription={subscription}
                            avatarUrl={avatarUrls[index]}
                            isActive={selectedSubscriptions.includes(subscription)}  
                            handleClick={() => handleLinkClick(subscription)}                          
                        />
                    ))}
        </div>
    );
};