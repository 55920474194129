import React from 'react';

export const CheckboxComponent = ({isChecked, setIsChecked}) => {
    // Состояние чекбокса
   

    // Обработчик изменения состояния чекбокса
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // переключаем состояние
    };

    return (
        <span className={`input-checkbox no-select ${isChecked ? "checked" : ""}`} onClick={handleCheckboxChange}>multiple QR</span>      
    );
};

