import React, { useEffect, useState } from 'react';
import { getCurrentDate } from '../../utils/appUtils';

export const BurnCountdownTimer = ({start_date, end_date, timeEnd, setTimeEnd, timeStop, timezone, setNotStart}) => {
   
    const endDate = new Date(end_date);

    const startDate = new Date(start_date);

    const [mess, setMess] = useState("")

    const [timer, setTimer] = useState(null)

    

    
    console.log("timeEndBurn", timeEnd)
   
   // console.log("endDate, startDate", endDate, startDate)
    
    
    
    const [timeLeft, setTimeLeft] = useState({});

    const formatTime = (time) => {
        return time < 10 ? `0${time}` : time; // Добавляем ведущий ноль, если цифра одна
    };

    const formatDays = (days) => {
        return days > 1 ? `${days} days ` : `${days} day `
    };

    const calculateTimeLeft = () => {

        // if (timeEnd || timeStop) {
        //     if (timer) {
        //         clearInterval(timer)
        //         setTimer(null)
        //     }
        //     setActive(false)
        //     return;
        // }
       console.log(123)
       console.log("localTimeEnd", timeEnd)
       
        const now = getCurrentDate(timezone)
        console.log("now", now)
        const endPoint = startDate > now ? startDate : endDate < now ? now : endDate
        const is_start = startDate < now
        if (!is_start) {
            setNotStart(true)
        }
        else {
            setNotStart(false)
        }

        const is_end = endDate < now
       console.log("is_end", is_end)
       setMess(startDate > now ? "time until the event starts" : endDate < now ? "this event is over" : "time remaining until the event ends")
        if (is_end) {
            setTimeEnd(true);
            clearInterval(timer); 
            setTimer(null);
            setTimeLeft({});
            return;

        }

   

        
       
      

        const difference = endPoint - now;

        if (difference <= 0) {
            setTimeLeft({}); // Если время вышло, сбросим состояние
            return;
        }

        const totalHours = Math.floor(difference / (1000 * 60 * 60)); // Всего часов до endPoint
        const days = Math.floor(difference / (1000 * 60 * 60 * 24)); // Полные дни до endPoint
        const hours = totalHours % 24; // Часы, оставшиеся после полного дня
        const minutes = Math.floor((difference / (1000 * 60)) % 60); // Минуты
        const seconds = Math.floor((difference / 1000) % 60); // Секунды

        setTimeLeft({
            hours: formatTime(hours),
            minutes: formatTime(minutes),
            seconds: formatTime(seconds),
            days: days !== 0 ? formatDays(days) : null
        });
    };

    useEffect(() => {
        console.log("useEffect for calculating time");
        if (timeEnd || timeStop) {
            if (timer) {
                clearInterval(timer);
                setTimer(null);
            }
            return; // Если timeEnd или timeStop истекли, ничего не делаем
        }
    
        calculateTimeLeft(); // Начинаем подсчет времени
    
        const newTimer = setInterval(calculateTimeLeft, 1000);
        setTimer(newTimer);
    
        return () => {
            clearInterval(newTimer); // Очистка таймера при размонтировании или обновлении
        };
    }, [timeEnd, timeStop]); // Зависимости
    
    // useEffect(() => {
    //     console.log("timer state has changed: ", timer);
    //     if (timeEnd || timeStop) {
    //         if (timer) {
    //             clearInterval(timer);
    //             setTimer(null);
    //         }
    //     }
    // }, [timeEnd, timeStop]);

    return (
        <>
            
            <div className="countdown-timer">
                <p className='label'>{mess}</p>
                {Object.keys(timeLeft).length !== 0 && (
                    <p className='timer'>
                    {timeLeft.days ? timeLeft.days : ""}{timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
                    </p>
                ) 
                }
            </div>
        </>
    );
};