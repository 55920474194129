


const calcLineWidth = (count, count_all) => {
    if (count>=count_all) {
        return "100%"
    }
    const part = count/count_all
    return `${(100*part).toString()}%`

}





export const IndicatorField = ({count, count_all, label}) => {

    return (
        <div className="indicator-field">
            <span className={`indicator-field-text ${count > 0 ? "active" : ""}`}>{count}/{count_all}{label ? `${label}` : ""}</span>
            <span className="indicator-field-background" style={{width: calcLineWidth(count, count_all)}}></span>
        </div>
    )
}