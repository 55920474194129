import { useEffect, useRef, useState } from 'react'
import menuIcon from '../../img/lkMenuIcon.svg'
import avatarPlaceholder from "../../img/userAvatarIcon.png"
import accountIcon from "../../img/accountIcon.svg"
import referralsIcon from "../../img/referralsIcon.png"
import aboutIcon from "../../img/aboutIcon.svg"
import supportIcon from "../../img/supportIcon.svg"
import statisticsIcon from "../../img/statisticsIcon.svg"
import { Link, useNavigate } from 'react-router-dom'

export const HeaderPopupMenu = ({username, avatarUrl, ifNotSavingData, setIsWarningOpen, setPath, setComingSoonOpen}) => {
   
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate()
    
    const modalRef = useRef(null);
    const menuIconRef  = useRef(null);

    

    const onIconClick = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target) && (!menuIconRef.current || !menuIconRef.current.contains(event.target))) {
                onClose();
            }
        };

        // Добавляем обработчик события при монтировании компонента
        document.addEventListener('mousedown', handleClickOutside);

        // Удаляем обработчик события при размонтировании компонента
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const onClose = () => {
        setIsOpen(false)
    }

    const handleLinkClick = (link) => {
       
        onClose()
        if (!ifNotSavingData) {
            navigate(link)
        }
        else {
            setPath(link)
        }
        setIsWarningOpen(ifNotSavingData)
        
    }


    return (
        <>
            <div className='header-menu-container no-select'>
                <div className={`header-menu-icon ${isOpen ? "is-open" : ""}`} onClick={onIconClick} ref={menuIconRef}></div>
                {(isOpen) && (
                    
                    <div className='modal-overlay' style={{zIndex: 98}}>
                        <div className="header-popup-menu" ref={modalRef}>

                            <div className='user-box'>
                                <img src={avatarUrl ? avatarUrl : avatarPlaceholder} alt="avatar" className='avatar'/>
                                <p className='username' onClick={()=>handleLinkClick('/account')}>@{username ? username : "username"}</p>
                            </div>
                            <div className='header-popup-items'>
                                <div className="header-popup-menu-item">
                                    <img src={statisticsIcon} alt="avatar" className='icon'/>
                                    <p className='item-label' onClick={() => {
                                        onClose()
                                        setComingSoonOpen(true)
                                        
                                        }}>Statistics</p>
                                </div>
                                <div className="header-popup-menu-item">
                                    <img src={referralsIcon} alt="avatar" width={24} height={24} className='icon'/>
                                    <p onClick={()=>handleLinkClick('/account/referral-program')} className='item-label'>Referrals</p>
                                </div>
                                <div className="header-popup-menu-item">
                                    <img src={supportIcon} alt="avatar" className='icon'/>
                                    <a className='item-label' href="https://t.me/LidumSup" target="_blank" rel="noopener noreferrer" onClick={onClose}>Support</a>
                                </div>
                                
                            </div>

                            
                        </div>
                    </div>
                    
            )}
            </div>
            
        </>
    )
}

/* <div className='modal-overlay'></div> */