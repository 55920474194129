import placeholder from "../../img/loadImagePlaceholder.png"
import { getRandomImage } from "../../utils/minterConnections";
import { getCurrentDatetimeLocal } from "../../utils/minterUtils";
import loadIcon from "../../img/load.svg"
import randomIcon from "../../img/random.svg"
import placeholderSelected from "../../img/loadImageSelected.png"


const getFileUrl = file => new Promise((resolve, reject) => {

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => {
        console.log(error);
        reject(null)
    };
});

const getBase64ImageExtention = (base64Image) => {
    const [header, base64Data] = base64Image.split(',');

   
    if (header.startsWith('data:')) {
        const mimeType = header.match(/:(.*?);/)[1]; // Извлекаем MIME-тип

        let extension;

        switch (mimeType) {
            case 'image/png':
                extension = 'png';
                break;
            case 'image/jpeg':
                extension = 'jpg';
                break;
            default:
                extension = '';
                break;
        }

           
            return extension;
        } else {
            //console.log('Не удалось определить тип данных.');
            return "";
        }
}



export const LoadImage = ({id, alt, selectedFileUrl, setSelectedFileUrl, setImageName, readOnly, addError, removeError, errorsMap, imgWidth, imgHeight, isCircle}) => {

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        

        if (file) {
           
            
            const fullFileUrl = await getFileUrl(file)

            if (fullFileUrl) {
                const extension = getBase64ImageExtention(fullFileUrl)
                if (!extension) {
                    console.log("Неверный тип расширения")
                    setSelectedFileUrl(null)
                    addError("image", "Invalid extension type! Select an image in png or jpg format", "error")
                    return null
                }
                if (errorsMap.get("image")) {
                    removeError("image")
                }
              
                console.log("handleFileChange extention", extension)
                console.log(`usr_img_${getCurrentDatetimeLocal()}.${extension}`)
                setImageName(`usr_img_${getCurrentDatetimeLocal()}.${extension}`)
                setSelectedFileUrl(fullFileUrl)
            }
            
           
            return fullFileUrl
        }
        else {
            return null
        }

    }

    const handleRandomGen = async () => {
        
    
        const image = await getRandomImage()
       
    
        if (image)
        {
            const extension = getBase64ImageExtention(image)
            setSelectedFileUrl(image)
            setImageName(`rnd_nft_${getCurrentDatetimeLocal()}.${extension}`)
            document.getElementById(id).src = image;
            
        }
    }

    const handleLoadImage = async (e) => {
        const fileUrl = await handleFileChange(e)
                
        if (fileUrl) {
          
            document.getElementById(id).src = fileUrl;
            
        }
    }

    return (
       <>
       
       <div style={{position: "relative", width: `${imgWidth}px`, height: `${imgHeight}px`, marginBottom: "9px"}} className="choose-image-box" >
      
        <div  onClick={handleLoadImage}  className={`load-file-container`}>
            <div className="collection_image_container">

                <img src={selectedFileUrl ? selectedFileUrl : placeholderSelected} className="img" id={id} style={{minWidth: `${imgWidth}px`, minHeight: `${imgHeight}px`, height: `${imgHeight}px`, width: `${imgWidth}px`, borderRadius: `${isCircle ? "50%" : "0"}`}} alt={alt}/>
                
            </div>

            {(!readOnly) && (
                
                <>
               
                <input type="file" id="file_input" className="load_img" accept="image/*" onChange={handleLoadImage} style={{cursor: "pointer"}} />
                </>
            ) 

            }
          
            
           </div>         
            
            
        </div>

        {(!readOnly) && (
                
            <div className={`btn random-btn no-select`}  style={{width: `${imgWidth}px`}} onClick={handleRandomGen}>Random gen</div> 
            ) 

            }
       
     </>
    )
}