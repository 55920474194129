import { useEffect, useState } from "react";
import DateTimeInput from "./DateTimeInput";
import { getCurrentDate } from "../../utils/appUtils";
import { MuiCalendar } from "./MuiCalendar";
import startIcon from "../../img/startIcon.svg"
import endIcon from "../../img/endIcon.svg"

import { TimeZoneSelector } from "./NumberSelector";



export const MuiCalendarsBox = ({ start, end, setStart, setEnd, timeZone, setTimeZone, readOnly }) => {
    const [startError, setStartError] = useState(false);
    const [endError, setEndError] = useState(false);

    useEffect(() => {
        if (readOnly) {
            return
        }
        const errors = validateDates()
        setStartError(errors[0]);
        setEndError(errors[1]);
    }, [start, end, timeZone, readOnly]);


    const validateDates = () => {

        

       // const now = new Date();
      const now = getCurrentDate(timeZone)
      // console.log("now", now)
        // Учитываем временную зону (timeZone в часах)
        
    
    // Создаем даты с учетом временной зоны
         const startDate = new Date(start);
         //console.log("startDate", startDate)
         const endDate = new Date(end);
        // console.log("endDate", endDate)

        // Проверка даты начала
        const isStartError = startDate <= now;
        

        // Проверка даты конца
        const isEndError = endDate <= now || startDate >= endDate;
     
        return [isStartError, isEndError]
    };



    return (
        <div className="time-fields-box">
            <MuiCalendar value={start} setValue={setStart} error={startError} readOnly={readOnly} icon={startIcon} label={"Start"}
                />
            <MuiCalendar value={end} setValue={setEnd} error={endError} readOnly={readOnly} icon={endIcon} label={"End"} />             
            <TimeZoneSelector timeZone={timeZone} setTimeZone={setTimeZone} readOnly={readOnly} /> 
        </div>
    );
};