
import { useEffect, useState } from "react"

import "./Account.css"
import { useNavigate, useSearchParams } from "react-router-dom"
import copyIcon from "../../img/copyIcon.svg"
import { PopupWindow, RepeatingPopupWindow } from "../../components/General/PopupWindow"
import { IndicatorField } from "../../components/General/IndicatorField"
import { AccountInputField } from "../../components/Account/AccountInputField"
import { getReferralInfo } from "../../utils/generalConnections"
import copyCheck from "../../img/copyCheck.svg"

export const ReferralPage = ({wallet, username, firstname, avatarUrl, telegramId, referralCode, referralLink, referrerCode, setReferrerCode, discount, setDiscount, balance, referralLevel, levelTreshold, referralsCnt, setIsBackButtonShow, setIsCopyPopupOpen}) => {

    const navigate = useNavigate()
    const [referrerInputError, setReferrerInputError] = useState(null)
    const [isCopiedLink, setIsCopiedLink] = useState(false)
    const [isCopiedCode, setIsCopiedCode] = useState(false)
    const [backendReferrerCode, setBackendReferrerCode] = useState(referrerCode)

    const onAccountBackButtonClick = () => {
        console.log("onAccountBackButtonClick referral")
        navigate(-1)
      }
    
    // referral_info = {
    //     "referee_link": referral.referee_link,
    //     "users_referral_link": referral.users_referral_link,
    //     "discount": referral.discount,
    //     "balance": referral.balance,
    //     "commission_percentage": referral.commission_percentage,
    //     "referrals_cnt": referral.referrals_cnt,
    //     "referral_level": referral.referral_level,
    //     "level_threshold": referral.level_threshold,
    // }

    const copyText = (text) => {
        // navigator.clipboard.writeText(text);
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        setIsCopyPopupOpen(true)
    }

    const handleInputReferrerEnd = async (code) => {
        if (!code) {
            setReferrerInputError(null)
            return
        }
        const referral_info = await getReferralInfo(telegramId, username, code)
        if (referral_info && referral_info.referee_link && referral_info.referee_link === code) {
            setDiscount(referral_info.discount)
            setReferrerInputError(null)
            setReferrerCode(referral_info.referee_link)
            setBackendReferrerCode(referral_info.referee_link)
            return
        }
        //setDiscount(0)
        setReferrerInputError("Referrer not found")
    
    }

    useEffect(() => {
        setIsBackButtonShow(true)
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.onEvent('backButtonClicked', onAccountBackButtonClick);
      }
      return () => {
          if (window.Telegram && window.Telegram.WebApp) {
              window.Telegram.WebApp.offEvent('backButtonClicked', onAccountBackButtonClick);
          }
         
      
      }
   
    }, [])

    // useEffect( () => {
    //     setIsBackButtonShow(true)
    //     setBackButtonEvent(() => () => navigate(-1))
    // }, [])
    
    const handleReferralInputBlur = () => {
        setReferrerCode(backendReferrerCode)
        setReferrerInputError(null)
    }

    return (
        <>
            {/* <RepeatingPopupWindow message={"Text copied to clipboard"} isOpen={clipboardWarningWindowOpen} setIsOpen={setClipboardWarningWindowOpen}/> */}
            <div className="referral-box">
                <div className="referral-box-item">
                    <p className="referral-item-label">Referral link</p>
                    <p className="referral-box-item-field">
                    <span>{"https://t.me...refer-"+referralCode}</span>
                        <img src={isCopiedLink ? copyCheck : copyIcon} className="referral-copy-img" alt="copy referral link" onClick={() => {
                            copyText(referralLink)
                            setIsCopiedLink(true)
                            }}/>
                    </p>
                </div>
                <hr align="center" size="1" color="#3A3F43" className="referral-box-line" />
                <div className="referral-box-item">
                    <p className="referral-item-label">Referral code</p>
                    <p className="referral-box-item-field">
                        <span>{referralCode}</span>
                        <img src={isCopiedCode ? copyCheck : copyIcon} className="referral-copy-img" alt="copy referral link" onClick={() => {
                            copyText(referralCode)
                            setIsCopiedCode(true)
                            }}/>
                    </p>
                </div>
            </div>
            <div className="indicator-field-box">
            <p className="referral-item-label">Level: {referralLevel}</p>
            <IndicatorField count={referralsCnt} count_all={levelTreshold} />
            <p className="indicator-field-info">Get access to level {referralLevel+1} for {levelTreshold} referrals</p>
            </div>
            <div className="account-input-field-box">
                <AccountInputField value={referrerCode} setValue={setReferrerCode} handleReferralInputBlur={handleReferralInputBlur} handleInputEnd={handleInputReferrerEnd} />
               
                {referrerInputError && (
                    <>
                    <p className="input-field-error">{referrerInputError}</p>
                    </>
                )

                }
                <p className="discount-text">Discount: {discount}%</p>
            </div>
            <div className="indicator-field-box">
            <p className="referral-item-label">Balance</p>
            <IndicatorField count={balance} count_all={5} label={" Ton"}/>
            <p className="indicator-field-info">Reward: 10%</p>
            </div>

        </>
    )
}