import { useEffect, useRef, useState } from "react";
import { checkSubscription } from "../../utils/minterUtils";
import channelNotFound from "../../img/channelNotFound.png"
import { getAvatar } from "../../utils/generalConnections";


export const NewSubscriptionsInputBox = ({subscriptions, errorIndices, addSubscription, removeSubscription, replaceSubscription, channelDublicateIndices, channelNotExistIndices, channelInvalidFormatIndices, onFocus, channelAvatars, addOrReplaceChannelAvatar, removeChannelAvatar, lastFocusIndex, setLastFocusIndex, handleAppearanceKeyboard, readOnly}) => {
    const [mainButton, setMainButton] = useState(null)
    const inputRefs = useRef([]);
    const timeoutRefs = useRef([]);
    const [currentFocusIndex, setCurrentFocusIndex] = useState(null)
    const currentFocusRef = useRef(null);
   
    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            var MainButton = window.Telegram.WebApp.MainButton
            if (MainButton) {
                setMainButton(MainButton)
                MainButton.text = "Apply"                
            }
        }
    }, [])

    const closeKeyboard = () => {
        if (currentFocusRef && currentFocusRef.current) {
            currentFocusRef.current.blur(); // Убирает фокус с текстового поля
        }
        if (mainButton) {
            mainButton.hide()
        }
    };
   

    useEffect( () => {
        console.log("lastFocusIndex", lastFocusIndex)
        if (lastFocusIndex !== null && inputRefs.current[lastFocusIndex]) {
            inputRefs.current[lastFocusIndex].focus(); 
        }
        return () => setLastFocusIndex(null);

    }, [subscriptions])


    useEffect( () => {
        if (currentFocusRef && currentFocusRef.current) {
            handleAppearanceKeyboard(currentFocusRef)
            if (mainButton) {
                mainButton.onClick(function() {  
                    closeKeyboard()                
                    
                  });
                  mainButton.show()
            }
        }
        else {
            if (mainButton) {
                mainButton.hide()
            }
            
        }
        
        console.log("currentFocusRef, currentFocusRef.current", currentFocusRef, currentFocusRef ? currentFocusRef.current : "" )

    }, [currentFocusRef.current])

    const onChange = (e) => {
        
        const index = e.target.dataset.key; // Индекс элемента
        var value = e.target.value.toLowerCase(); // Текущее значение input
        if (value[0] && value[0] !== "@") {
            value = "@" + value
        }   
        const idx = parseInt(index, 10);
        
        if (idx === 0) {
            return
        }
        if (timeoutRefs.current[idx]) {
            clearTimeout(timeoutRefs.current[idx]);
          }
      
      
          timeoutRefs.current[idx] = setTimeout(async () => {
            await handleInputEnd(value, idx);
          }, 300);

        if (idx === subscriptions.length) {
            if (value !== "") {
                addSubscription(value)

               
            // setTimeout(() => {
            //     if (inputRefs.current[idx]) {
            //         inputRefs.current[idx].focus(); 
            //     }
            // }, 0);
            }
            return           
        }

        if (value === "") {
            if (mainButton && idx === subscriptions.length - 1) {
                mainButton.hide()
            }
            removeSubscription(idx);
            
            return         
        }        
        replaceSubscription(value, idx); 
        console.log("subscription", subscriptions[idx])

        
    };


    const addNewStyle = (id) => {
        document.getElementById(id).classList.add("new-input")   
    }

    const removeNewStyle = (id) => {
        document.getElementById(id).classList.remove("new-input")   
    }
   
   const handleInputEnd = async (subscription) => {

    if (getAvatarUrl(subscription)) {
        return
    }
    
   const avatarUrl = await getAvatar(subscription);
   //const avatarUrl = "123"
    if (avatarUrl) {
        addOrReplaceChannelAvatar(subscription, avatarUrl)
    }
    clearAvatarsChannel()
   }

 

   const clearAvatarsChannel = () => {
    for (let subscription of channelAvatars.keys()) {
        if (!subscriptions.includes(subscription)) {
            removeChannelAvatar(subscription)
        }
      }
   }

   

    const getMessage = (index) => {
        if (channelInvalidFormatIndices.includes(index)) {
            return "Invalid format"
        }
        if (channelNotExistIndices.includes(index)) {
            return "Channel doesn’t exist"
        }
        if (channelDublicateIndices.includes(index)) {
            return "Repeating channel"
        }
    }
    
    const getAvatarUrl = (subscription) => {
        if (channelAvatars.get(subscription)) {
            return channelAvatars.get(subscription)
        }
        return null
    }


    return (
       
        <div className="subscriptions-input-box">
            
            <span className="channel-with-avatar-container">
            
            <span className="form-input default-subscription channel-input channel-with-avatar readonly-input-field">{subscriptions[0]}</span>
            <img className="channel-avatar" src={getAvatarUrl(subscriptions[0])} alt={`${subscriptions[0]} avatar`} width={41} height={41}/>
            </span>
            {subscriptions.slice(1).map((subscription, index) => (
                <>
                <div className="channel-with-avatar-container">
                <input
                    className={`form-input channel-input ${errorIndices.includes(index + 1) ? 'error' : "" } channel-with-avatar ${readOnly ? "readonly-input-field" : "" }`}
                    readOnly={readOnly}
                    type="text"
                    ref={el => inputRefs.current[index + 1] = el}
                    id={`subscription_${index + 1}`}
                    data-key={index + 1}
                    key={index + 1}
                    onChange={onChange}
                    onFocus={() => {
                        if (readOnly) {
                            return
                        }
                        onFocus(index + 1)
                        setCurrentFocusIndex(index + 1)
                        setLastFocusIndex(index + 1)
                        currentFocusRef.current = inputRefs.current[index + 1];

                    }}
                    name={`subscription_${index + 1}`}
                    value={subscriptions[index + 1]} 
                    required
                    maxLength={256}
                    
                    onBlur={() => 
                   { 
                    if (readOnly) {
                        return
                    }
                    setCurrentFocusIndex(null)
                    //resetFocus()
                    currentFocusRef.current = null;
                   
                    }
                    
                    }
                />
                <img className="channel-avatar" src={getAvatarUrl(subscription) ? getAvatarUrl(subscription) : channelNotFound} alt={subscription + " avatar"} />
                
               
                </div>
                {errorIndices.includes(index + 1) && (
                     <p className="input-field-error" style={{marginTop: "-5px"}}>{getMessage(index + 1)}</p>   
                )}
                {(!errorIndices.includes(index + 1) && subscription !== "" && !getAvatarUrl(subscription)) && (
                     <p className="input-field-error" style={{marginTop: "-5px"}}>Channel doesn’t exist</p>   
                )}
                </>
                
            ))}

            {!readOnly && (

                <input
                className={`form-input channel-input new-input`}
                type="text"
                ref={el => inputRefs.current[subscriptions.length] = el}
                id={`subscription_${subscriptions.length}`}
                key={subscriptions.length}
                data-key={subscriptions.length}
                onChange={onChange}
                name={`subscription_${subscriptions.length}`}
                defaultValue=""
                maxLength={256}
                onFocus={() => {
                    removeNewStyle(`subscription_${subscriptions.length}`)
                    setCurrentFocusIndex(subscriptions.length)
                    setLastFocusIndex(subscriptions.length)
                    currentFocusRef.current = inputRefs.current[subscriptions.length]

                    
                    }
                }

                onBlur={(e) => {
                    addNewStyle(`subscription_${subscriptions.length}`)    
                    setCurrentFocusIndex(null)             
                    //resetFocus()
                    currentFocusRef.current = null;
                }}
                
            />
                )
            }
           
        </div>
    );
};