import { TonConnectButton } from "@tonconnect/ui-react"

export const IfWalletNotConnected = ({wallet, message}) => {
    return (
    <>
        <h2 className="connect-message">{message}</h2>
        <TonConnectButton className='tonConnectButton' style={wallet ? {display: "none"} : {display: "block"}}/>
    </>
)
}

export const NoAccessPage = ({message}) => {
    return (
        <>
            <h2 className="connect-message">{message}</h2>
        </>
    )
   
}