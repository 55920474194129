import placeholder from "../../img/loadImagePlaceholder.png"
import placeholderSelected from "../../img/loadImageSelected.png"
import placeholderUnSelected from "../../img/loadImageUnselected.png"
import { getRandomImage } from "../../utils/minterConnections";
import { getCurrentDatetimeLocal } from "../../utils/minterUtils";
import loadIcon from "../../img/load.svg"
import randomIcon from "../../img/random.svg"

const getFileUrl = file => new Promise((resolve, reject) => {

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => {
        console.log(error);
        reject(null)
    };
});

const getBase64ImageExtention = (base64Image) => {
    const [header, base64Data] = base64Image.split(',');

   
    if (header.startsWith('data:')) {
        const mimeType = header.match(/:(.*?);/)[1]; // Извлекаем MIME-тип

        let extension;

        switch (mimeType) {
            case 'image/png':
                extension = 'png';
                break;
            case 'image/jpeg':
                extension = 'jpg';
                break;
            default:
                extension = '';
                break;
        }

           
            return extension;
        } else {
            //console.log('Не удалось определить тип данных.');
            return "";
        }
}



export const NewLoadImage = ({id, alt, selectedFileUrl, setSelectedFileUrl, setImageName, readOnly, addError, removeError, errorsMap, imgWidth, imgHeight, isCircle, isSelected, setSelected}) => {

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        

        if (file) {
           
            
            const fullFileUrl = await getFileUrl(file)

            if (fullFileUrl) {
                const extension = getBase64ImageExtention(fullFileUrl)
                if (!extension) {
                    console.log("Неверный тип расширения")
                    setSelectedFileUrl(null)
                    addError("image", "Invalid extension type! Select an image in png or jpg format", "error")
                    return null
                }

                setImageName(`usr_img_${getCurrentDatetimeLocal()}.${extension}`);
                setSelectedFileUrl(fullFileUrl);
                if (errorsMap.get("image")) {
                    removeError("image")
                }

                return fullFileUrl

                //  // Создаем новый объект Image для получения размеров
                //  return new Promise((resolve, reject) => {
                //     const img = new Image();
                //     img.src = fullFileUrl;
    
                //     img.onload = () => {
                //         const { width, height } = img;
    
                //         if (width > 512 || height > 512) {
                //             console.log("Изображение превышает максимальные размеры (512x512)");
                //             setSelectedFileUrl(null);
                //             addError("image", "Image dimensions should not exceed 512x512 pixels.", "error");
                //             reject("Размеры изображения слишком велики");
                //         } else {
                //             console.log("handleFileChange extension", extension);
                //             console.log(`usr_img_${getCurrentDatetimeLocal()}.${extension}`);
                //             setImageName(`usr_img_${getCurrentDatetimeLocal()}.${extension}`);
                //             setSelectedFileUrl(fullFileUrl);
                //             resolve(fullFileUrl); // Успешно загружено
                //         }
                //     };
    
                //     img.onerror = (error) => {
                //         console.error("Ошибка загрузки изображения", error);
                //         reject("Ошибка загрузки изображения");
                //     };
                // });
            
            }

            return null;
           
        }
        else {
            return null
        }

    }

    const handleLoadClick = () => {
        console.log("handleLoadClick id isSelected", id, isSelected)
        if (!isSelected) {
            setSelected()
        }
    }

    const handleLoadImage = async (e) => {
        console.log("id isSelected", id, isSelected)

        if (!isSelected) {
            return;
        }
        
        try {
            const fileUrl = await handleFileChange(e);
            
            if (fileUrl) {
                // Установка src только после успешной загрузки изображения
                document.getElementById(id).src = fileUrl;
                if (errorsMap.get("image")) {
                    removeError("image")
                }
            }
        } catch (error) {
            console.error(error); // Логируем ошибку, если что-то пошло не так
        }

       
    }

    return (
       <>
       
       <div style={{position: "relative", width: `${imgWidth}px`, height: `${imgHeight}px`}} className="choose-image-box" >
      
        <div onClick={handleLoadClick}  className={`load-file-container ${(isSelected && !readOnly) ? "is-selected" : ""}`}>
            <div className="collection_image_container">

                <img src={selectedFileUrl ? selectedFileUrl : isSelected ? placeholderSelected : placeholderUnSelected} className="img" id={id} style={{minWidth: `${imgWidth}px`, minHeight: `${imgHeight}px`, height: `${imgHeight}px`, width: `${imgWidth}px`, borderRadius: `${isCircle ? "50%" : "0"}`}} alt={alt}/>
                
            </div>

            {(!readOnly && isSelected) && (
                
                <>
               
                <input type="file" id="file_input" className="load_img" accept="image/*" onChange={handleLoadImage} style={{cursor: "pointer"}} />
                </>
            ) 

            }
          
            
           </div>         
            
            
        </div>

        
     </>
    )
}