import "./Checker.css"
import placeholder from "../img/imagePlaceholder.png"
import { useEffect, useRef, useState } from "react"
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkPassword, getUserInfo, sendDataToServer, setInitialParams } from "../utils/checkerConnections";
import { SubscriptionList } from "../components/Checker/SubscriptionList";
import { CountdownTimer } from "../components/Checker/CountdownTimer";
import { LoadingIndicator } from "../utils/appUtils";
import { PopupContainer } from "../components/General/PopupWindow";
import { IfWalletNotConnected, NoAccessPage } from "../components/General/IfWalletNotConnected";
import { LeaveCheckerWindow } from "../components/General/ModalWindow";
import { InputField } from "../components/General/InputField";


const formattedDatetime = (datetimeLocalString) => {
    // Преобразование строки в объект Date
    const date = new Date(datetimeLocalString);

    // Получение дня и месяца
    const day = String(date.getDate()).padStart(2, '0');    
    const month = String(date.getMonth() + 1).padStart(2, '0');

    // Форматируем результат
    return `${day}.${month}`
}





export const Checker = ({wallet, tonConnectUI, errorsMap, setErrorsMap, telegramId, username}) => {

    
   
    const [isLoading, setIsLoading] = useState(true);
    const [eventStatus, setEventStatus] = useState(null)

    const [passwordCheckedClass, setPasswordCheckedClass] = useState("")
    const [isGetInactive, setIsGetInactive] = useState(false)
    const [isCurrentEvent, setIsCurrentEvent] = useState(false)
    const passwordRef = useRef(null)
    const [isInputFocused, setIsInputFocused] = useState(false)
   
    //const [messageForOverlay, setMessageForOverlay] = useState(null)
    const [timeEnd, setTimeEnd] = useState(false)

    const [visitedChannels, setVisitedChannels] = useState([])

    const [nftStatus, setNftStatus] = useState(null)

    const [error, setError] = useState(null);

    const [openLeaveWindow, setOpenLeaveWindow] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams();

    const [password, setPassword] = useState("");
    // const [invites, setInvites] = useState([""]);

    const eventHash = searchParams.get("event_hash") ? searchParams.get("event_hash") : null;
    console.log('searchParams.get("event_hash")', searchParams.get("event_hash") )

    console.log("openLeaveWindow", openLeaveWindow)
    
    const passwordDefaultText = "Password"
    
    

    const [collectionParams, setCollectionParams] = useState({
        collection_name: "",
        event_name: "",
        description: "",
        logo: "",
        start_date: "",
        end_date: "",
        invites: 0,
        subscriptions: [],
        subscriptions_avatar_urls: [],
        minted_nfts: 0,
        nfts_cnt: 0,
        empty_password: false
    });


    
    const [selectedSubscriptions, setSelectedSubscriptions] = useState([])



    const addError = (errorType, errorMessage, errorStatus) => {
        setErrorsMap((prevErrors) => {
            // Создаем новый Map, копируя предыдущие ошибки
            const newErrorsMap = new Map(prevErrors);
            
            // Добавляем новую ошибку или обновляем существующую
            newErrorsMap.set(errorType, {
                status: errorStatus,
                message: errorMessage,
            });
            
            return newErrorsMap; // Возврат нового Map
        });
    };
    
    const removeError = (errorType) => {
        setErrorsMap(prevErrors => {
            const newErrorsMap = new Map(prevErrors);
            newErrorsMap.delete(errorType); // Удаление ошибки по типу
            return newErrorsMap; // Возврат обновленного Map
        });
    };

 
    const Overlay = () => {
        if (!timeEnd) {
            return null
        }
        return (
          <div className="overlay-window">
            <p class="overlay-window-message">this event is over</p>
          </div>
        );
      };

      useEffect(() => {
        setErrorsMap(new Map())
       
    }, []);
      

    useEffect(() => {
        setErrorsMap(new Map())
       
    }, []);


    const handleAppearanceKeyboard = (ref) => {

        // setIsInputFocused(true)
        // Прокручиваем с учетом высоты клавиатуры
        const currentKeyboardHeight = 205
        setTimeout(() => {
            const { top } = ref.current.getBoundingClientRect();
            const scrollOffset = top + window.scrollY - currentKeyboardHeight;
            console.log("top, scrollOffset, currentKeyboardHeight", top, scrollOffset, currentKeyboardHeight)
            // Уменьшите значение высоты на 20-30 пикселей (например 20)
            window.scrollTo({
              top: scrollOffset, // Уменьшение наперед
              behavior: 'smooth',
            });
          }, 400);
      };


    useEffect(() => {
        const fetchData = async () => {
            console.log("useEffect eventHash", eventHash)
            setIsLoading(true); // начинаем загрузку
            try {
            
                
                await setInitialParams(eventHash, {setCollectionParams, setEventStatus, setIsGetInactive});
                // if (!is_success) {
                //     return
                // }
                // if (!collectionParams.minted_nfts || !collectionParams.images_cnt) {
                //     setError(error);
                //     setEventStatus("Error receiving data")
                //     return
                // }
                // if (collectionParams.minted_nfts >= collectionParams.images_cnt) {
                //     setCntError("distributed")
                //     setIsGetInactive(true)
                //     return
                // }
                //console.log("fetchData", deactiveFields)
            } catch (error) {
                setError(error); // устанавливаем ошибку, если нужно
                setEventStatus("Error receiving data")
                console.log(error);
            } finally {
                setIsLoading(false); // завершили загрузку
            }
        };

        fetchData();
    }, [searchParams]);

    
  

    useEffect(() => {
        if (isCurrentEvent && !errorsMap.get("nft") && !nftStatus) {
            setIsGetInactive(false)
        }
     }, [isCurrentEvent]);


    useEffect(() => {
        const fetchUserInfo = async () => {

               if (!username) {
                return
               }
                try {
                    const user_info = await getUserInfo(telegramId, username, eventHash); 
                    // const user_info = {
                    //     visited_channels: ["@rasfocuse", "@FarmerDao", "@channel1"],
                    //     participated_events: []
                    // }
                    if (!user_info) {
                        setEventStatus("User not found")
                        return
                    }
                    
                    const visited_channels = user_info.visited_channels
                    if (visited_channels) {
                        setVisitedChannels(visited_channels)
                    }

                    const is_participated = user_info.participated 
                    if (is_participated) {
                        
                        addError("nft", "You have already received the nft from this event", "error")
                        setNftStatus(true)
                        setIsGetInactive(true)
                    }

                } catch (error) {
                    console.log(error)
                    setEventStatus("Server Error")
                    
                }
               
                

              
                
               
            
        };

        fetchUserInfo(); // Вызов функции для получения информации о пользователе
    }, [username]);

    // if (!wallet) return (
    //     <>
    //         <IfWalletNotConnected wallet={wallet} message={"Connect your wallet to see this event"} />
    //     </>
    // )
    
    if (eventStatus) return (
        <>
             <NoAccessPage message={eventStatus} />
        </>
    )
   

    if (isLoading) {
       
        return <LoadingIndicator />;
    }

    if (error) {
        
        return <div className="dataLoading">Error: {error.message}</div>;
    }

    const getNftMessage = (status) => {

        switch (status) {
            case "success":
                return "Congratulations, the NFT has been sent to your wallet!"
            case "error":
                return "Server Error"
            case "NOT_FOUND":            
                return "Event not found"
            case "DB_READING_ERROR":
                return "Server Error"
            case "DB_WRITING_ERROR":
                return "Server Error"
            case "SERVER_ERROR":
                return "Server Error"                      
            case "EVENT_NFTS_LEFT":
                return "All the NFTs from this event have already been distributed"
            case "REPEAT_USER":
                return "You have already received the NFT from this event"
    
            case "MINT_ERROR":
                return "Error when trying to mint NFT into a collection"
    
            case "NFT_TRANSFER_ERROR":
                return "Error when trying to send the NFT"           
    
            default:
                return "We are sending the NFT..."
        }
    }

    const handleConnect = async (e) => {
        e.preventDefault()
        try {
            await tonConnectUI.openModal()
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    }; 

    const connect = async () => {
        try {
            await tonConnectUI.openModal()
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    }

  
    const checkValidation = () => {
        for (let i=0; i<collectionParams.subscriptions.length; i++) {
            const channel = collectionParams.subscriptions[i]
            //console.log("selectedSubscriptions.includes(channel) channel", selectedSubscriptions.includes(channel), channel)
            if (!selectedSubscriptions.includes(channel)) {
                return false
            }
        }

        if (!collectionParams.empty_password) {

            if (!password) {
                return false
            }
        }

        return true
    }

  

    const handleSubmit = async (e) => {
        e.preventDefault();
  
        //console.log("handleSubmit collectionParams", collectionParams.subscriptions)
        //console.log("handleSubmit selectedSubscriptions", selectedSubscriptions)
      
        for (let i=0; i<collectionParams.subscriptions.length; i++) {
            const channel = collectionParams.subscriptions[i]
            //console.log("selectedSubscriptions.includes(channel) channel", selectedSubscriptions.includes(channel), channel)
            if (!selectedSubscriptions.includes(channel)) {
                
                addError("subscriptions", 'Subscribe to all channels!', "error")
                return
            }
        }
        removeError("subscriptions")

        // if (!invites || invites.length === 0) {
        //     alert('Choose your invites!')
        //     return
        // }
        // if (!checkSubscriptionsNew(invites)) {
        //     alert('Invalid invitations entered! Subscriptions must start with "@" and must not contain illegal characters!')
        //     return
        // }
        // const invites_arr = checkSubscriptionsNew(invites)


        // console.log("invites_arr", invites_arr)
        
        if (!collectionParams.empty_password) {

            const error = await checkPassword(eventHash, password);
            //console.log("handleSubmit error password", error)
                //setPasswordCheckedClass
            if (error) {
                const message = error === "wrong" ? "Wrong password" : "Password verification error"
                addError("password", message, "error")
                setPasswordCheckedClass("error")
                // setIsGetInactive(true)
                return
            }
            removeError("password")
            setPasswordCheckedClass("success")
        }
        setIsGetInactive(true)
     
       
    
        const send_json = {
            event_hash: eventHash,
            wallet_address: tonConnectUI.account.address,
            telegram_id: telegramId
        }

       //setLoadingSendNftStatus("loading");
        addError("nft", getNftMessage("loading"), "loading")
        setNftStatus(true)
        // Вызов функции отправки данных
        const send_status = await sendDataToServer(send_json);
        if (send_status === "success") {
            setOpenLeaveWindow(true)
            removeError("nft")
        }
        else {
            addError("nft", getNftMessage(send_status), "error")
        }
       //console.log("send_status", send_status)
      
        // Сбрасываем состояние загрузки
        //setLoadingSendNftStatus(send_status);
        //console.log("setLoadingSendNftStatus", setLoadingSendNftStatus)
        
        
      
           
       


    };

  
  


    return (
        <>
        
      
            <div className="checker-box" style={{marginBottom: "170px"}}>
            

            
           
            <Overlay />

            <LeaveCheckerWindow isOpen={openLeaveWindow}/>
          
                 <div className="collection_description_container">
                <div className="collection_image_container info">
                    <img src={collectionParams.logo} alt="Event logo" className="img" id="img" />
                </div>
                <div className="collection_summary">
                    <h1 className="collection_title">{collectionParams.collection_name}</h1>
                    <div className="collection_description" style={{marginBottom: "25px"}}>{collectionParams.event_name}</div>                                   
                    <div className="collection_description">{collectionParams.description}</div> 
                </div>
               
            </div>
            <div className="collection_data">
                    <form className="collection_data_form" onSubmit={ async (e) => {
                           //setPassword(e.target[0].value) 
                         if (!wallet) {                       
                            await handleConnect(e);
                        }
                        else {

                            handleSubmit(e);
                        }
                       
                    } } >
                         {!collectionParams.empty_password && (
                            <>
                            
                            <div style={{marginBottom: "10px"}}>
                                <InputField label={"Password"} name={"password"} value={password} setValue={setPassword} readOnly={isGetInactive} required={true} defaultText={passwordDefaultText} />
                            </div>

                            {/* <div className="form-item">
                                <label className="form-item-label">password</label>
                                <input className={`form-input ${isGetInactive ? "readonly-input" : ""} ${passwordCheckedClass}`} readOnly={isGetInactive} type="text" name="password" value={password} ref={passwordRef} onChange={(e) => setPassword(e.target.value.toLowerCase())} maxLength={32}
                                onFocus={() => handleAppearanceKeyboard(passwordRef)}
                                // onBlur={() => setIsInputFocused(false)}
                                 />
                            </div> */}
                            </>
                            
                          )
                         }   
                       
                        
                            <SubscriptionList 
                                subscriptions={collectionParams.subscriptions} 
                                selectedSubscriptions={selectedSubscriptions}
                                setSelectedSubscriptions={setSelectedSubscriptions} 
                                avatarUrls = {collectionParams.subscriptions_avatar_urls}
                                telegramId = {telegramId}
                                readOnly={(nftStatus || timeEnd)}
                                visitedChannels={visitedChannels}
                            />
                     
                        
                        {/* <div className="form-item" style={{ marginBottom: '26px' }}>
                            <label className="form-item-label">invite</label>
                            
                            <InvitesInputBox invites={invites} setInvites={setInvites} />
                        </div> */}
                        <CountdownTimer start_date={collectionParams.start_date} end_date={collectionParams.end_date} setTimeEnd={setTimeEnd} nftStatus={nftStatus} setIsGetInactive={setIsGetInactive} setIsCurrentEvent={setIsCurrentEvent} timeZone={collectionParams.user_timezone}/>

                        {(isGetInactive || !checkValidation()) ? (
                            <div className="btn form-btn-next no-select readonly-btn" onClick={() => {
                                const copiedMap = new Map();
                                errorsMap.forEach((value, key) => {
                                    copiedMap.set(key, value);                                    
                                });
                                setErrorsMap(copiedMap)
                            }}>Next</div>
                        )  :                     
                        (
                            <input className="btn form-btn-next no-select" type="submit" value="Next" />
                        )
                        }
                      
                    </form>
    
                    
                </div>
           
            </div>
        
        
      
        </>
    )

    


}