


const formNftsArr = (nfts_pictires, need_to_burn_nfts) => {
    const newArr = nfts_pictires.slice(0)


    for (let i = nfts_pictires.length; i < need_to_burn_nfts; i++) {
        newArr.push("")
        
    }

    return newArr
}

const formMainArr = (nfts_pictires) => {
    
    const last = nfts_pictires.length > 3 ? 3 : nfts_pictires.length
    const newArr = nfts_pictires.slice(0, last)
    

    return newArr
}

const formRemainingArr = (nfts_pictires) => {
    if (nfts_pictires.length <= 3 ) {
        return []
    }
    const last = nfts_pictires.length > 10 ? 10 : nfts_pictires.length
    const newArr = nfts_pictires.slice(3, last)

    return newArr

}

const calcLeftPosition = (index) => {
    const x = 14
    const width = 29
    const position = index * (width - x)
    return `${position.toString()}px`
}

export const NftsContainer = ({nfts_pictires}) => {

    

    const mainArr = formMainArr(nfts_pictires)

    const remainingArr = formRemainingArr(nfts_pictires)
    

    return (
  
    <div className="nfts_pictures_container">
        <div className="nfts-pictires-main">
            {mainArr.map((picture, index) => (

                <div className="nft_picture" key={`nft_${index}`}>
                    <img src={picture} />
                </div>

            ))

            }
        </div>
        <div className="nfts-pictires-remaining">
            {remainingArr.map((picture, index) => (

                <div className="nft_picture" key={`nft_${index}`} style={{left: calcLeftPosition(index), zIndex: ((remainingArr.length - index)*10)}}>
                    <img src={picture} />
                </div>

                ))
            }
        </div>

    </div>
    
    )
}