import { useEffect, useRef } from "react";
import { checkSubscription } from "../../utils/minterUtils";
import avatarPlaceholder from "../../img/imagePlaceholder.png"

export const SubscriptionsInputBox = ({subscriptions, errorIndices, setErrorIndices, addSubscription, addNotVerifiedSubscription, removeSubscription, removeNotVerifiedSubscription, replaceSubscription, notVerifiedSubscriptions, channelDublicateIndices, channelNotExistIndices, channelInvalidFormatIndices, onFocus, isSubscriptionsChecked, successSubscriptions, channelAvatars}) => {

    const inputRefs = useRef([]);


    const closeKeyboard = (index) => {
        if (inputRefs[index].current) {
            inputRefs[index].current.blur(); // Убирает фокус с текстового поля
        }
    };
   
    // channelDublicateIndices={channelDublicateIndices} channelNotExistIndices={channelNotExistIndices} channelInvalidFormatIndices={channelInvalidFormatIndices} 
    
    // const handleBlur = async (value, index) => {
    //     const idx = parseInt(index, 10);
    //     const formatted_subscription = checkSubscription(value);
    
    //         if (!formatted_subscription) {
    //             setErrorIndices((prev) => [...prev, idx]);
    //             console.log("Неверный формат.");
    //             setMessage("channel does not exist or is the same as previously entered")
    //             return
    //             //setErrorIndices((prev) => [...new Set([...prev, ...errors])]);
                
    //         }
    //         const countValue = subscriptions.filter(item => item === formatted_subscription).length;
    //         if (countValue > 1) {
    //             // const lastIndex = subscriptions.lastIndexOf(formatted_subscription)
    //             // if (!errorIndices.includes(lastIndex)) {
    //             //     setErrorIndices((prev) => [...prev, lastIndex]);
    //             // }   
    //             setErrorIndices((prev) => [...prev, idx]);
    //             setMessage("channel is the same as previously entered")
    //             return
    //         }
    //         replaceSubscription(formatted_subscription, idx);
    // }


    const onChange = (e) => {
        const index = e.target.dataset.key; // Индекс элемента
        const value = e.target.value.toLowerCase(); // Текущее значение input
        const idx = parseInt(index, 10);
        
        if (idx === 0) {
            return
        }
        if (idx === subscriptions.length) {
            if (value !== "") {
                addSubscription(value)
                addNotVerifiedSubscription(idx)
                // if (inputRefs.current[idx]) {
            //             inputRefs.current[idx].focus(); 
            //         }

            setTimeout(() => {
                if (inputRefs.current[idx]) {
                    inputRefs.current[idx].focus(); 
                }
            }, 0);
            }
            return           
        }

        if (value === "") {
            removeSubscription(idx);
            removeNotVerifiedSubscription(idx)
            return         
        }        
        replaceSubscription(value, idx); 
        addNotVerifiedSubscription(idx)
        console.log("subscription", subscriptions[idx])
    };


    const addNewStyle = (id) => {
        document.getElementById(id).classList.add("new-input")   
    }

    const removeNewStyle = (id) => {
        document.getElementById(id).classList.remove("new-input")   
    }
   
   

    const getMessage = (index) => {
        if (channelInvalidFormatIndices.includes(index)) {
            return "invalid format"
        }
        if (channelNotExistIndices.includes(index)) {
            return "channel is not exist"
        }
        if (channelDublicateIndices.includes(index)) {
            return "repeating channel"
        }
    }
    
    const getAvatarUrl = (index) => {
        if (channelAvatars.get(index)) {
            return channelAvatars.get(index)
        }
        return null
    }


    return (
       
        <div className="subscriptions-input-box">
        
            <span className="channel-with-avatar-container">
            
            <span className="form-input default-subscription channel-input channel-with-avatar">{subscriptions[0]}</span>
            <img className="channel-avatar" src={getAvatarUrl(0)} alt={"@lidumapp avatar"} width={41} height={41}/>
            </span>
            {subscriptions.slice(1).map((subscription, index) => (
                <>
                <div className="channel-with-avatar-container">
                <input
                    className={`form-input channel-input ${errorIndices.includes(index + 1) ? 'error' : "" } ${getAvatarUrl(index + 1) ? "channel-with-avatar" : ""}`}
                    type="text"
                    id={`subscription_${index + 1}`}
                    data-key={index + 1}
                    key={index + 1}
                    onChange={onChange}
                    // onBlur={async (e) => {
                    //     const errors = await handleBlur(e.target.value, index+1)
                    //     console.log("onBlur e.target.value, index+1", e.target.value, index+1)
                        
                    //     }}
                    onFocus={() => {
                        onFocus(index + 1)
                    }}
                    name={`subscription_${index + 1}`}
                    value={subscriptions[index+1]} 
                    required
                    maxLength={256}
                    ref={el => inputRefs.current[index + 1] = el}
                />
                {getAvatarUrl(index + 1) && (
                    <img className="channel-avatar" src={getAvatarUrl(index + 1)} alt={subscription + " avatar"} />
                )
                }
                <span className="close-keyboard" onClick={() => closeKeyboard(index + 1)}>х</span>
                {/* <img className="channel-avatar" src={avatarPlaceholder} alt={subscription + " avatar"} /> */}
               
                </div>
                {errorIndices.includes(index + 1) && (
                     <p className="error-message">{getMessage(index + 1)}</p>   
                )}
                </>
                
            ))}
            <input
                className={`form-input channel-input new-input`}
                type="text"
                id={`subscription_${subscriptions.length}`}
                key={subscriptions.length}
                data-key={subscriptions.length}
                onChange={onChange}
                name={`subscription_${subscriptions.length}`}
                defaultValue=""
                maxLength={256}
                onFocus={() => {
                    removeNewStyle(`subscription_${subscriptions.length}`)
                    }}
                // onBlur={async (e) => {
                //     addNewStyle(`subscription_${subscriptions.length}`)
                      
                //     // console.log("e.target.value, ", e.target.value)
                //     }}
                ref={el => inputRefs.current[subscriptions.length] = el}
            />
        </div>
    );
};