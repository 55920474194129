import { DateCalendar, DatePicker, MobileDateTimePicker, MobileTimePicker, MultiSectionDigitalClock, TimeField, TimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, TextField, Button, IconButton } from "@mui/material";


import AccessTimeIcon from '@mui/icons-material/AccessTime';

const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const year = String(date.getFullYear());

  return `${day}.${month}.${month}.${year} ${hours}:${minutes}`;
};

const formatDate = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());
  return `${day}.${month}.${month}.${year}`;
}

const formatTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}



const formatDateTimeLocal = (day, month, year, hour, minute) => {
  // Добавляем ноль перед единичными значениями дня и месяца
  const formattedDay = String(day).padStart(2, '0');
  const formattedMonth = String(month).padStart(2, '0');
  const formattedYear = String(year);
  const formattedHour = String(hour).padStart(2, '0');
  const formattedMinute = String(minute).padStart(2, '0');

  // Формируем строку в формате datetime-local
  return `${formattedYear}-${formattedMonth}-${formattedDay}T${formattedHour}:${formattedMinute}`;
};



const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const theme = createTheme({
    components: {
      MuiCalendarPicker: {
        styleOverrides: {
          day: {
            color: 'white', // Цвет шрифта для всех дней
          },
        },
      },
    },
  });

export const MuiCalendar = ({value, setValue, error, readOnly, icon, label }) => {

  const [isOpen, setIsOpen] = useState(false)

  
  
  const [dateMonth, setDateMonth] = useState(value ? dayjs(value) : dayjs())  
  const [time, setTime] = useState(value ? dayjs(value) : dayjs());
  const openCalendar = () => {
      setIsOpen(!isOpen)
  }

  const handleResize = () => {
    const box = document.querySelector('.clear-or-apply-date-box');
    if (box) {
      box.style.bottom = `${Math.max(0, window.innerHeight - box.getBoundingClientRect().bottom)}px`;
    }
  };
  
  useEffect(() => {
    if (isOpen) {
        document.body.style.overflow = 'hidden'; // Запретить прокрутку
    } else {
        document.body.style.overflow = 'unset'; // Включить прокрутку
    }

    // Возврат функции для очистки эффекта
    return () => {
        document.body.style.overflow = 'unset'; // Очистить стиль при размонтировании
    };
}, [isOpen]);
  
  
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCombineDateTime = () => {
  
  const combinedDateTime = dayjs(dateMonth).hour(time.hour()).minute(time.minute());

   
    // Преобразование в формат datetime-local
    const datetimeLocalValue = combinedDateTime.format("YYYY-MM-DDTHH:mm");

    return datetimeLocalValue; // Вернуть или использовать как вам нужно
  };


  // const dateTimeLocalFormat = date.format('YYYY-MM-DDTHH:mm');

    // const day = date.date(); // День
    // const month = date.month() + 1; // Месяц (0-11, поэтому +1)
    // const year = date.year(); // Год
    // const minutes = date.minute(); // Минуты
    // const seconds = date.second(); // Секунды

    const onClear = () => {
      setIsOpen(false)
      setDateMonth(dayjs())
      setTime(dayjs())
      setValue(null)
    }

    const onCancel = () => {
      setIsOpen(false)
      if (value) {
        setDateMonth(dayjs(value))
        setTime(dayjs(value))
      }
      else {
        setDateMonth(dayjs())
        setTime(dayjs())
      }
     
    }

    const onConfirm = () => {
      setIsOpen(false)
      setValue(handleCombineDateTime())
      
    }

    if (readOnly) return (
      <div className={`form-input mui-calendar-input no-select readonly-input`}>
          {value ? formatDateTime(value) : ""}
        </div>
    )

    return (
        <>

          <div className={`time-field no-select ${(error && value) ? "input-error" : ""}`} onClick={openCalendar}>
            <img src={icon} className="time-field-icon"/>
            <span className="time-field-value"> {value ? formatDate(value) : label}</span>       

            <span className="time-field-value"> {value ? formatTime(value) : ""}</span>    
          </div>

 

            <ThemeProvider theme={darkTheme}>
            {/* <CssBaseline /> */}
            {isOpen && (
                <div className="modal-overlay calendar-overlay">
            <div className="modal-window modal-calendar-window">
            <span className="clear-calendar" onClick={onClear}>clear</span>
           
                <DateCalendar 
                showDaysOutsideCurrentMonth
                  value={dateMonth} 
                  onChange={(newDate) => {
                    setDateMonth(newDate)
                    }}  
                  views={['day']}
                  className="mui-calendar"

            />
            <div className="calendar-bottom-box">
                <div className="time-input-box">
               
                  <MobileTimePicker 
                    label="select time"
                    ampm={false} 
                    value={time}
                    onChange={(newValue) => {
                      setTime(newValue);
                    }}
               
                    
                    sx={{
                      
                      "& .css-18ekah5-MuiInputBase-root-MuiOutlinedInput-root": {padding: 0, height: "50px"
                      },
                      "& .css-8a1qk4-MuiInputBase-input-MuiOutlinedInput-input": {padding: "0 15px"
                      },

                                      
                    }} 
                  />
                      
                  </div>
                  <div className="time-buttons-box">
                  <Button onClick={onCancel}  type="button" className="clear-or-apply-btn"  sx={{ color: '#90caf9', backgroundColor: "transparent",  width: "71px" }}  variant="contained">CANCEL</Button>
                  <Button onClick={onConfirm}  type="button" className="clear-or-apply-btn"  sx={{ color: '#90caf9', backgroundColor: "transparent",  width: "71px" }}  variant="contained">OK</Button>
                  </div>
                  </div>
                </div>
                {/* <div className="clear-or-apply-date-box">
                  <Button onClick={onCancel}  type="button" className="clear-or-apply-btn"  variant="contained" // Установите стиль, как у "Ok" кнопки
          
          sx={{ color: '#90caf9', backgroundColor: "transparent" }}
          
          >CANCEL</Button>
                  <Button type="button" onClick={onConfirm} className="clear-or-apply-btn"  variant="contained" // Установите стиль, как у "Ok" кнопки
          
          sx={{ color: 'white',
          backgroundColor: "#42a5f5",
          
           }}
          
          >APPLY</Button>
                </div> */}
                </div>
            )}
          
            </ThemeProvider>
        </> 
    )
}