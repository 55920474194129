import { TonConnectButton } from "@tonconnect/ui-react"
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom"
import logoPlaceholder from "../img/imagePlaceholder.png"
import { EventPreview } from "../components/General/EventPreview";
import { BurnCountdownTimer } from "../components/Burn/BurnCountdownTimer";
import { IfWalletNotConnected, NoAccessPage } from "../components/General/IfWalletNotConnected";
import "./Burn.css"
import picturePlaceholder from "../img/eventBackgroundDark.jpg"
import picturePlaceholderSecond from "../img/eventBackgroundLight.jpg"
import { NftsContainer } from "../components/Burn/NftsContainer";
import { TimeEndOverlay } from "../components/General/ModalWindow";
import { LoadingIndicator } from "../utils/appUtils";
import { addBurnTransaction, getAuthorNfts, getBurnStatus, sendBurnedNfts } from "../utils/burnConnections";
import { createTransaction, getTransactionStatus, newGetTransactionStatus } from "../utils/generalConnections";
import TonWeb from "tonweb";

export const NftBurning = ({wallet, tonConnectUI, popupMessagesMap, setPopupMessagesMap, addPopupMessage, removePopupMessage, errorsInMessageMap, showMessagesAgain, telegramId, loadingsInMessageMap}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const dropId = searchParams.get("drop_id") ? searchParams.get("drop_id") : null;
    const [dropStatus, setDropStatus] = useState(true)
    const [isLoading, setIsLoading] = useState(true);
    const [dropParams, setDropParams] = useState({
        collection_name: "My first NFT",
        event_name: "My first event",
        description: "I need NFT-token. It should be an orange smiley face",
        drop_image: logoPlaceholder,
        start_date: "2024-10-12T21:05",
        end_date: "2024-10-15T21:49",
        user_timezone: 7,
        nft_amount: 5,
    });
    // 1 - addresses( массив строк с адресами нфт которые могут быть сожжены)
    // 2 - images (массив строк, пикчи нфт которые нужно сжечь пользователю)
    // 3 - start_date (string)
    // 4 - end_date (string)
    // 5 - collection_name (sting) [имя нужной коллекции]
    // 6 - nft_amount (количество нфт котрое должен сжечь пользователь) (int)
    // 7 - drop_image (аватарка ивента сжигания) 
    // 8 - user_timezone (таймзона которая была указана при создании ивента)
    const [userNftsInfo, setUserNftsInfo] = useState({
        addresses: ["1", "2", "3", "1", "2", "3", "1", "2", "3"],
        images: [logoPlaceholder, picturePlaceholder, picturePlaceholderSecond, logoPlaceholder, picturePlaceholder, picturePlaceholderSecond, logoPlaceholder, picturePlaceholder, picturePlaceholderSecond, picturePlaceholder, picturePlaceholderSecond],
        nfts_count: 11
    })

  

  
    const nfts_pictires = [logoPlaceholder, picturePlaceholder, picturePlaceholderSecond, logoPlaceholder, picturePlaceholder]

    const [timeEnd, setTimeEnd] = useState(false)
    const [burnActive, setBurnActive] = useState(false)
    const [timeStop, setTimeStop] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(null)
    const [intervalId, setIntervalId] = useState(null);
    const [notStart, setNotStart] = useState(false)

    const [burnedNfts, setBurnedNfts] = useState([])

    const comission = 0.00727257 

    useEffect( () => {
        const fetchData = async () => {
            if (wallet) {
            setIsLoading(true); // начинаем загрузку
            try {
                //console.log("useEffect event_id", event_id)
                const walletAddress = tonConnectUI.account.address;
               
               const status_drop = await getBurnStatus(telegramId, walletAddress, dropId)
               if (status_drop === true) {
                setDropStatus(true)
               }
               if (status_drop===false) {
                    setShowErrorMessage("You have already received this prize.")
                    return
               }
               if (!status_drop) {
                     //setShowErrorMessage("Error receiving data")
                     return
               }

               const burningParams = await getAuthorNfts(walletAddress, dropId)
               if (!burningParams) {
                return
               }
               setDropParams({
                    collection_name: burningParams.collection_name,
                    event_name: "My first event",
                    description: burningParams.description,
                    drop_image: burningParams.drop_image,
                    start_date: burningParams.start_date,
                    end_date: burningParams.end_date,
                    user_timezone: Number(burningParams.user_timezone),
                    nft_amount: Number(burningParams.nft_amount),
                })
                setUserNftsInfo({
                    addresses: burningParams.addresses,
                    images: burningParams.images,
                    nfts_count: Number(burningParams.addresses.length)
                })
               
                
            } catch (error) {
                // setDropParams({
                //     collection_name: "My first NFT",
                //     event_name: "My first event",
                //     description: "I need NFT-token. It should be an orange smiley face",
                //     drop_image: logoPlaceholder,
                //     start_date: "2024-10-12T21:05",
                //     end_date: "2024-10-15T21:49",
                //     user_timezone: 7,
                //     nft_amount: 5,
                // })
                // setUserNftsInfo({
                //     addresses: ["1", "2", "3"],
                //     images: [logoPlaceholder, picturePlaceholder, picturePlaceholderSecond],
                //     nfts_count: 3
                // })
                setShowErrorMessage("Error receiving data")
                console.log(error);
            } finally {
                setIsLoading(false); // завершили загрузку
                if (userNftsInfo.nfts_count < dropParams.nft_amount) {
                    addPopupMessage("nft", "Not enough NFTs to burn", "error")
                }
            }

            }
        };

        fetchData();
    }, [wallet, tonConnectUI])

    useEffect(() => {
        if (notStart || timeEnd || popupMessagesMap.has("nft") || loadingsInMessageMap() || !dropStatus) {
            setBurnActive(false)
        }
        else {
            setBurnActive(true)
        }
    }, [notStart, timeEnd, popupMessagesMap, dropStatus]); 

    
    if (!wallet) return (
        <>
            <IfWalletNotConnected wallet={wallet} message={"Connect your wallet to burn NFTs"} />
        </>
    )

    if (isLoading) {
       
        return <LoadingIndicator />;
    }
    
    if (showErrorMessage) return (
        <>
            <NoAccessPage message={showErrorMessage} />
        </>
    )

    const handleBurnClick = async () => {
        if (!burnActive) {
            showMessagesAgain()
            return
        }




        await handleBurn()
        
    }

    const handleBurn = async () => {
        let burned_count = 0
        while (burned_count < dropParams.nft_amount) {
            const count_part = dropParams.nft_amount > 4 ? 4 : dropParams.nft_amount
            const status = await handleTransaction(count_part)
            if (status === "success") {
                burned_count += count_part
                addPopupMessage('burn', `${count_part} NFTs burned`, "success")
                updateUserNfts(burnedNfts)
                const send_burned_nfts = await sendBurnedNfts(burnedNfts)
            }
            else {
                break
            }
        }
    }

    const handleTransaction = async (burning_nfts_count) => {
        const wallet_address = tonConnectUI.account.address
        const transaction_hash = await createBurnTransaction(burning_nfts_count, wallet_address)
        
        if (!transaction_hash) {
            return
        }

        const transaction_id = handleAddTransaction(transaction_hash)
        if (!transaction_id) {
            return
        }
        const status = await waitForTransactionSuccess(transaction_id);
        if (status === "success") {
            setDropStatus(false)
            
        }
        return status

    }

    const updateUserNfts = (burned_nfts) => {
        var images = []
        var nfts = []
        for (let i=0; i<userNftsInfo.nfts_count; i++) {
            if (!burned_nfts.includes(userNftsInfo.addresses[i])) {
                images.push(userNftsInfo.images[i])
                nfts.push(userNftsInfo.addresses[i])                    
            }
        }
        setUserNftsInfo({
            addresses: nfts,
            images: images,
            nfts_count: nfts.length
        })
    }

    const handleAddTransaction = async (transaction_hash) => {
        const wallet_address = tonConnectUI.account.address
        
        const transaction_id = await addBurnTransaction(transaction_hash, wallet_address)
        if (transaction_id) {
            return transaction_id
        }        
        
    }

    const waitForTransactionSuccess = (transaction_id) => {
        return new Promise((resolve, reject) => {
            
            const id = setInterval(() => { 
                newGetTransactionStatus(transaction_id).then(status => {
                    if (status === "success") {
                        clearInterval(id);
                        setIntervalId(null)
                        removePopupMessage("transaction")
                        resolve("success");
                    } else if (status === "error") {
                        clearInterval(id);
                        setIntervalId(null)                        
                        addPopupMessage("transaction", "There was an error processing the transaction. Please try again", "error")
                        reject(new Error("Transaction failed"));
                    }
                });
            }, 5000);
        });
    }

   
   
    const calcLineWidth = (count, count_all) => {
        if (count>=count_all) {
            return "100%"
        }
        const part = count/count_all
        return `${(100*part).toString()}%`

    }

    const createBurnTransaction = async (burning_nfts_count, wallet_address) => {
        const recipient_address = "EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c";
        
        

        try {
           

            const responseAddress = new TonWeb.utils.Address(wallet_address);

            if (!TonWeb.utils.Address.isValid(responseAddress)) {
                throw new Error('Invalid wallet address');
            }

            const recipientTonAddress = TonWeb.utils.Address.isValid(recipient_address) ? new TonWeb.utils.Address(recipient_address) : null;

            if (!recipientTonAddress) {
                throw new Error('Invalid recipient address');
            }

            const messages = [];

            let i = 0;
            let count = 0
            const burnedArr = []
            while (count < burning_nfts_count || i >= userNftsInfo.addresses.length) {
                const nftAddress = userNftsInfo.addresses[i]                
                i++
                const nftTonAddress = TonWeb.utils.Address.isValid(nftAddress) ? new TonWeb.utils.Address(nftAddress) : null;

                if (!nftTonAddress) {
                    console.warn(`Invalid NFT address skipped: ${nftAddress}`);                    
                    continue;
                }

                const transferBody = new TonWeb.boc.Cell();
                transferBody.bits.writeUint(0x5fcc3d14, 32);
                transferBody.bits.writeUint(0, 64);
                transferBody.bits.writeAddress(recipientTonAddress);
                transferBody.bits.writeAddress(responseAddress);
                transferBody.bits.writeBit(false);
                transferBody.bits.writeCoins(TonWeb.utils.toNano('0.05'));
                transferBody.bits.writeBit(false);

                messages.push({
                    address: nftTonAddress.toString(),
                    amount: TonWeb.utils.toNano('0.1').toString(),
                    payload: TonWeb.utils.bytesToBase64(await transferBody.toBoc())
                });
                count++;
                burnedArr.push(nftAddress)

              }

           

            if (messages.length < dropParams.nft_amount) {
                addPopupMessage("nft", "Not enough valid NFTs to burn", "error")
                return null
            }

            const response = await tonConnectUI.sendTransaction({
                validUntil: Math.floor(Date.now() / 1000) + 300,
                messages: messages
            });

            if (!response || !response.boc) {
                addPopupMessage("transaction", "Error sending transaction", "error")
                return null
            }
            
            const boc_cell = await TonWeb.boc.Cell.oneFromBoc(TonWeb.utils.base64ToBytes(response.boc)).hash();  
           
            const transaction_hash = TonWeb.utils.bytesToBase64(boc_cell);
           
            addPopupMessage("transaction", "Transaction in progress...", "loading")

            if (transaction_hash) {
                setBurnedNfts(burnedArr)
            }

            return transaction_hash


        } catch (error) {
            addPopupMessage("transaction", "Error creating transaction", "error")
            return null
        }
    }

    

    return (
        <>  
        <TimeEndOverlay timeEnd={timeEnd} />
         <EventPreview logo={dropParams.drop_image} event_name={dropParams.event_name} collection_name={dropParams.collection_name}/>
        <div style={{marginBottom: "17px"}}>
                <div className="nft-logos-and-count-container">
                 <NftsContainer nfts_pictires={userNftsInfo.images} />
                 <span className="nft-count-label">{userNftsInfo.nfts_count} items</span>
                </div>
                <div className="nft-indicator">
                    <span className="indicator-text">{userNftsInfo.nfts_count}/{dropParams.nft_amount}</span>
                    <span className="indicator-background" style={{width: calcLineWidth(userNftsInfo.nfts_count, dropParams.nft_amount)}}></span>
                </div>

              
                {/* <div className="nfts_info_container">
                    <p className="nfts_info_p">You have <span className="nfts_count_span">{userNftsInfo.nfts_count} NFT</span></p>
                    <NftsContainer nfts_pictires={userNftsInfo.images} need_to_burn_nfts={dropParams.nft_amount} />
                    <p>You need to burn <span className="nfts_count_span">{dropParams.nft_amount} NFT</span></p>

                 </div>    */}
                <BurnCountdownTimer start_date={dropParams.start_date} end_date={dropParams.end_date} timezone={dropParams.user_timezone} timeEnd={timeEnd} setTimeEnd={setTimeEnd} timeStop={timeStop} setNotStart={setNotStart} />
                

                
                <button className={`submit-btn no-select ${burnActive ? "is-connect" : "get-btn-inactive"}`} onClick={handleBurnClick}>burn</button>
            </div>

            <TonConnectButton className='tonConnectButton' style={wallet ? {display: "none"} : {display: "block"}}/>

        </>
    )
}