import { useEffect, useRef, useState } from "react";
import plusIcon from '../../img/plus.svg'

const closeKeyboard = (ref) => {
    if (ref.current) {
        ref.current.blur(); // Убирает фокус с текстового поля
    }
};

export const InputField = ({name, label, value, setValue, checkValidation, readOnly, defaultText, handleFocus, handleChange, handleBlur, required, maxLength, pattern, errorMessage}) => {

   
    const ref = useRef()
    const isScrollElementsName = ['button']
    const [mainButton, setMainButton] = useState(null)
   
    const [isError, setIsError] = useState(false)

   const isPlusVisible = !(value || readOnly)

    const [plusVisible, setPlusVisible] = useState(isPlusVisible)

    
    

    const text = value ? value : defaultText
    const [inputValue, setInputValue] = useState(text)

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            var MainButton = window.Telegram.WebApp.MainButton
            if (MainButton) {
                setMainButton(MainButton)
                MainButton.text = "Apply"
                MainButton.onClick(function() {
                    closeKeyboard(ref)
                  });
            }
        }

        return () => {
            if (MainButton) {
               MainButton.hide()
            }
        };
    }, [])
   

    useEffect(() => {
        if (value) {
            setInputValue(value);
        } 
    }, [value]);

    useEffect(() => {
        if (value || readOnly) {
            setPlusVisible(false)
        } 
    }, [value, readOnly]);

    const countValidation = (e) => {
        const pattern = /^([1-9][0-9]*)$/
        if (pattern.test(e.target.value) || e.target.value==="") {
            setValue(e.target.value)
            setInputValue(e.target.value)
        }
    }


    const getFullPattern = () => {

        if (readOnly) {
            return ".*"
        }

        const pattern0 = required ? ".+" : ".*"
        const pattern1 = `^(?!${defaultText}).+`

        if (!pattern) {
            if (value === defaultText || !required) {
                return pattern0
            }
            return pattern1
        }
        
        
        if (value === defaultText) {
            return pattern
            
        }
        return `(${pattern1})|(${pattern})`
        

        

        
    }

    const onFocus = (e) => {
        if (readOnly) {
            return
        }
        handleAppearanceKeyboard()
        if (mainButton) {
            mainButton.show()
        }
        
        setIsError(false)
        if (handleFocus) {
            handleFocus(e)
        }
        setPlusVisible(false)
        if (e.target.value === defaultText && value !== defaultText) {
            setInputValue("")
        }
        
    }


    const onBlur = (e) => {
        if (readOnly) {
            return
        }
        if (mainButton) {
            mainButton.hide()
        }
       
        if (handleBlur) {
            handleBlur(e)
        }
        
        if (e.target.value === "") {
            setInputValue(defaultText)
            setPlusVisible(true)
        }

      
        
        
    }

    const onChange = (e) => {
        if (readOnly) {
            return
        }
        if (checkValidation) {
            const is_success = checkValidation(e)
            setIsError(!is_success)
        }
        
        
        if (handleChange) {
            handleChange(e)
        }

        if (name === "count") {
            countValidation(e)
        }
        else {
            setValue(e.target.value)
            setInputValue(e.target.value)
        }
        
    }


    //divRef.current.style.background = 'red';

    const handleAppearanceKeyboard = () => {
        const isScroll = isScrollElementsName.includes(name) ? true : false
        if (readOnly && !isScroll) return
        if (!ref || !ref.current) return
       
        const currentKeyboardHeight = 205
        
        const scroll = () => {
            const { top } = ref.current.getBoundingClientRect();
            const scrollOffset = top + window.scrollY - currentKeyboardHeight;
 
            window.scrollTo({
                top: scrollOffset,
                behavior: 'smooth',
            });
        };

        requestAnimationFrame(() => {
            setTimeout(scroll, 400);
        });

      };

      const handlePlusClick = () => {
        setPlusVisible(false)
        if (ref && ref.current) {
            ref.current.focus()
        }
      }

    return (
        <>
            <div className={`input-field-container `}>
            <label className="input-field-label">{label}</label>
               
                <div className="input-content">
                
                    {plusVisible && (
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handlePlusClick} className="plus-svg">
                            <path d="M7.25 0V14.5" stroke="#737E87" strokeWidth="2"/>
                            <path d="M14.5 7.25L-4.76837e-07 7.25" stroke="#737E87" strokeWidth="2"/>
                            </svg>
                            )

                        }
                    <input 
                        name={name} 
                        type={name === "count" ? "tel" : "text"}
                        required={required ? true : false}
                        maxLength={maxLength ? maxLength : 524288}
                        value={inputValue} 
                        ref={ref} 
                        className={`input-field ${errorMessage ? "input-error" : ""} ${(!plusVisible && !readOnly) ? "active-field" : ""} ${readOnly ? "readonly-input-field" : ""}`}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        pattern={getFullPattern()}
                        readOnly={readOnly}
                    />
                 </div>

            </div>
            {errorMessage && (
                    <>
                    <p className="input-field-error">{errorMessage}</p>
                    </>
                )

                }
        </>
    )
}

export const TextareaField = ({name, label, value, setValue, checkValidation, readOnly, defaultText, handleFocus, handleChange, handleBlur, required, maxLength, descriptionEmptyAfterChange}) => {
    
    const ref = useRef()
    const containerRef = useRef()
    const [mainButton, setMainButton] = useState(null)
    const [isError, setIsError] = useState(false)

    const isPlusVisible = !(value || readOnly)
 
     const [plusVisible, setPlusVisible] = useState(isPlusVisible)
 
    
     const text = value ? value : defaultText
     const [inputValue, setInputValue] = useState(text)
 
 
     useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            var MainButton = window.Telegram.WebApp.MainButton
            if (MainButton) {
                setMainButton(MainButton)
                MainButton.text = "Apply"
                MainButton.onClick(function() {
                    closeKeyboard(ref)
                  });
            }
        }
    }, [])
 
     useEffect(() => {
         if (value) {
             setInputValue(value);
         } 
     }, [value]);
 
     useEffect(() => {
         if (value || readOnly) {
             setPlusVisible(false)
         } 
     }, [value, readOnly]);


    
   


    const isScrollElementsName = ['descriptionOfYourPost']

    const onFocus = (e) => {
        if (readOnly) {
            return
        }
        if (mainButton) {
            mainButton.show()
        }
        handleAppearanceKeyboard()
        setIsError(false)
        if (handleFocus) {
            handleFocus(e)
        }
        setPlusVisible(false)
        if (e.target.value === defaultText && value !== defaultText) {
            setInputValue("")
        }
        
    }


    const onBlur = (e) => {
        if (readOnly) {
            return
        }
        if (mainButton) {
            mainButton.hide()
        }
        if (handleBlur) {
            handleBlur(e)
        }
        
        if (e.target.value === "") {
            setInputValue(defaultText)
            setPlusVisible(true)
        }

      
        
        
    }

    useEffect ( () => {
        if (descriptionEmptyAfterChange) {
            descriptionEmptyAfterChange(plusVisible, value)
        }
        
    }, [plusVisible, value]

    )

   

    const onChange = (e) => {
        if (readOnly) {
            return
        }
        if (checkValidation) {
            const is_success = checkValidation(e)
            setIsError(!is_success)
        }
        setValue(e.target.value)
        setInputValue(e.target.value)
        if (handleChange) {
            handleChange(e)
        }
       
        
    }

 
    
    useEffect(() => {
        if (ref.current) {
            ref.current.style.height = 'auto'; // Скидываем высоту
            ref.current.style.height = `${ref.current.scrollHeight}px`; // Устанавливаем высоту на основе содержимого
        }
        if (containerRef.current) {
            containerRef.current.style.height = 'auto'; // Сбрасываем высоту
            containerRef.current.style.height = `${containerRef.current.scrollHeight}px`; // Устанавливаем высоту на основе содержимого
        }
    }, [value]);

  

    //divRef.current.style.background = 'red';

    const handleAppearanceKeyboard = () => {
        const isScroll = isScrollElementsName.includes(name) ? true : false
        if (readOnly && !isScroll) return
        if (!ref || !ref.current) return
       
        const currentKeyboardHeight = 205
        
        const scroll = () => {
            const { top } = ref.current.getBoundingClientRect();
            const scrollOffset = top + window.scrollY - currentKeyboardHeight;
 
            window.scrollTo({
                top: scrollOffset,
                behavior: 'smooth',
            });
        };

        requestAnimationFrame(() => {
            setTimeout(scroll, 400);
        });

      };

      const handlePlusClick = () => {
        setPlusVisible(false)
        if (ref && ref.current) {
            ref.current.focus()
        }
      }

    return (
        <>
            <div className={`input-field-container`} ref={containerRef}>
            <label className="input-field-label">{label}</label>
               
                <div className="input-content">
                
                    {plusVisible && (
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handlePlusClick} className="plus-svg">
                            <path d="M7.25 0V14.5" stroke="#737E87" strokeWidth="2"/>
                            <path d="M14.5 7.25L-4.76837e-07 7.25" stroke="#737E87" strokeWidth="2"/>
                            </svg>
                            )

                        }
                    <textarea 
                        name={name} 
                        required={required ? true: false}
                        maxLength={maxLength ? maxLength : 524288}
                        value={inputValue} 
                        ref={ref} 
                        className={`input-field ${isError ? "input-error" : ""} ${(!plusVisible && !readOnly) ? "active-field" : ""} ${readOnly ? "readonly-input-field" : ""}`}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        style={{ width: '100%', resize: 'none', overflow: 'hidden' }} // Убираем resize и скрываем overflow
                        rows={1} // Начальное количество строк 1
                        readOnly={readOnly}
                        
                    />
                 </div>
            </div>
        </>
    )

}