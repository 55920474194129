import { useEffect, useRef, useState } from "react";

const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
};

const DateTimeInput = ({ input_id, name, defaultValue, setDateTimeInput, error }) => {
    const dateTimeInputRef = useRef(null);
    const text_id = input_id + "_formatted";

    const [inputClass, setInputClass] = useState("")
   

    // Состояние для управления классом

    useEffect(() => {
      if (defaultValue === "") {
        return
      }
      
      setInputClass(error ? "error check-input" : "check-input");

     
     
  }, [error]);



    // Форматирование даты
    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}.${month} ${hours}:${minutes}`;
    };

    // Задаем начальное значение как отформатированную дату
    const text_default = defaultValue ? formatDateTime(defaultValue) : "";

    const handleChange = (event) => {
        const value = event.target.value;
        setDateTimeInput(value); // Вызываем функцию для обновления состояния

        const formattedDate = formatDateTime(value);
        document.getElementById(text_id).innerHTML = formattedDate;
        setInputClass(error ? "error check-input" : "check-input");
     

        // Установка класса на основе ошибки
       
    };

    const handleInputClick = () => {
        if (dateTimeInputRef.current) {
            dateTimeInputRef.current.showPicker(); // Для открытия календаря
        }
    };

    const handleSpanClick = () => {
        // Открываем календарь, имитируя клик на input
        if (dateTimeInputRef.current) {
            if (isAndroid()) {
                dateTimeInputRef.current.click();
            }
            else {
                dateTimeInputRef.current.showPicker();
            }
            
        }
    };
    return (
        <div>
            <input
                className={`form-input calendar-input no-select ${inputClass}`} // Классы можно передать, если нужно
                id={input_id}
                type="datetime-local"
                name={name}
                value={defaultValue}
                required
                ref={dateTimeInputRef}
                onClick={handleInputClick}
                onChange={handleChange}
                style={{paddingLeft: "40px", fontSize: 0}}

            />
            <span className={`datetime_formatted`} onClick={handleInputClick} id={text_id}>{text_default}</span>
            
        </div>
    );
};

export default DateTimeInput;