import { TonConnectButton } from "@tonconnect/ui-react"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { DateTimeInputBox } from "../components/General/DateTimeInputBox"
import "./Drop.css"
import { ComissionBox } from "../components/General/ComissionBox"
import { PtcToBurnInput } from "../components/Drop/PtcToBurnInput"
import { PopupFormErrorMessage, PopupTransactionFailed } from "../components/General/PopupWindow"
import { addTransaction, createTransaction, getTransactionStatus } from "../utils/generalConnections"
import TonWeb from "tonweb"



export const Drop = ({wallet, tonConnectUI}) => {
    
    const [start, setStart] = useState("")
    const [end, setEnd] = useState("")
    const [pageNumber, setPageNumber] = useState(1)
    const [ptcToBurn, setPtcToBurn] = useState("")
    const [prizes, setPrizes] = useState("")
    const [comission, setComission] = useState("")
    const [comissionToUSD, setComissionToUSD] = useState("")
    const [formErrorMessage, setFormErrorMessage] = useState(null)
    const [isTransactionError, setIsTransactionError] = useState(null)
    const [transactionId, setTransactionId] = useState(null)
    const [intervalId, setIntervalId] = useState(null);
    const [isDifferent, setIsDifferent] = useState(false)
    
     // Очищаем интервал при размонтировании компонента
     useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);
   
    
    const BackArrowButton = ({ pageNumber }) => {

        if (pageNumber === 1) {
            return (
                <Link to="/" className="arrow arrow-back" />
            )
        }
        return (
            <div className="arrow arrow-back" onClick={() => {
                setPageNumber(pageNumber - 1)
            }
            }>
            </div>
        )

    }

    const NextArrowButton = ({ pageNumber }) => {

        if (pageNumber >= pagesArr.length - 1) {
            return (
                <div></div>
            )
        }
        return (
            <input className="arrow arrow-next" type="submit" value="" />
        )

    }

    const handleConnect = async () => {
        try {
            await tonConnectUI.openModal()
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    };

   

    const ArrowBox = ({ pageNumber }) => {

        return (

            <div className="arrow-box">
                <BackArrowButton pageNumber={pageNumber} />
                <span></span>
            </div>

        )
    }

    const handlePayAndSend = () => {

    }

    const handleAddTransaction = async (transaction_hash) => {
        const wallet_address = tonConnectUI.account.address
        const transaction_id = await addTransaction(transaction_hash, comission, wallet_address)
        if (!transaction_id) {
            setIsTransactionError(true)
            setTransactionId(null)
            return null
        }
        setTransactionId(transaction_id)       
        return transaction_id
        
    }

    const handleCreateTransaction = async () => {
        try {
            console.log("Создается транзакция...")
            const transaction = await createTransaction(comission)
            console.log("handleCreateTransaction transaction", transaction)
            console.log("tonConnectUI.sendTransaction...")
            const response = await tonConnectUI.sendTransaction(transaction);
            console.log("TonWeb.boc.Cell.oneFromBoc...")
            const boc_cell = await TonWeb.boc.Cell.oneFromBoc(TonWeb.utils.base64ToBytes(response.boc)).hash();  
            console.log("TonWeb.utils.bytesToBase64...")      
            const transaction_hash = TonWeb.utils.bytesToBase64(boc_cell);
            console.log("transaction_hash", transaction_hash)
            const transaction_id = await handleAddTransaction(transaction_hash)
            
            if (!transaction_id) {
                setIsTransactionError(true)
                return 
            }
            
             // Запускаем интервал для получения статуса транзакции
             const id = setInterval(() => {
                getTransactionStatus({transaction_id, intervalId, setIntervalId});
            }, 5000);
            setIntervalId(id); // Сохраняем ID интервала
        } catch (error) {
            setIsTransactionError(true)
            setTransactionId(1)
            const transaction_id = 1;
            const id = setInterval(() => {
                getTransactionStatus({transaction_id, intervalId, setIntervalId});
            }, 5000);
            setIntervalId(id);
        }


    }   


    const DropFormPage = () => {
        return (
            <>
                
                <form className="form" style={{marginBottom: "17px"}} onSubmit={async (e) => {
                    e.preventDefault()
                    if (!wallet) {                       
                            await handleConnect();
                        }    
                    }}>
                    <DateTimeInputBox start={start} setStart={setStart} end={end} setEnd={setEnd} />
                    <div className="form-item">
                        <label className="form-item-label">ptc to burn</label>
                        <PtcToBurnInput setComission={setComission} ptcToBurn={ptcToBurn} setPtcToBurn={setPtcToBurn} setComissionToUSD={setComissionToUSD}/>
                    </div>
                    <div className="form-item with-use-different">
                        <label className="form-item-label">prizes</label>
                        {/* <span className="use-different">use different</span> */}
                        <input
                            className="form-input with-square"
                            type="text"
                            name="prizes"
                            value={prizes}
                            onChange={(e) => setPrizes(e.target.value)}
                            required
                            style={{marginBottom: "10px"}}
                            
                        />
                    </div>
                    <p className="total-minted-owners">total minted<br/>owners</p>
                    <ComissionBox comission={comission} comissionToUSD={comissionToUSD} />
                    
                    <input className={wallet ? "submit-btn is-connect" : "submit-btn"} type="submit" value="pay and send" style={{marginBottom: "65px"}} />
                </form>
            </>
        )
    }

    const pagesArr = ["/", DropFormPage]

    return (
        <>  
        
            <PopupFormErrorMessage message={formErrorMessage}  setStatus={setFormErrorMessage}  />
            <PopupTransactionFailed status={isTransactionError} />
            {pagesArr[pageNumber](pagesArr)}
            <ArrowBox pageNumber={pageNumber} />

            <TonConnectButton className='tonConnectButton' style={wallet ? {display: "none"} : {display: "block"}}/>

        </>
    )

}