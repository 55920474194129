import axios from "../axios";

  // referral_info = {
    //     "referee_link": referral.referee_link,
    //     "users_referral_link": referral.users_referral_link,
    //     "discount": referral.discount,
    //     "balance": referral.balance,
    //     "commission_percentage": referral.commission_percentage,
    //     "referrals_cnt": referral.referrals_cnt,
    //     "referral_level": referral.referral_level,
    //     "level_threshold": referral.level_threshold,
    // }



    export const addWithdrawTransaction = async (telegram_id, amount, wallet_address) => {
      try {
          const sending_json = {
            telegram_id: telegram_id,
            amount: amount,
            wallet_address: wallet_address,            
          }
          console.log("addWithdrawTransaction sending_json", sending_json)
          const {data} = await axios.post(`/api/withdraw_from_balance/`, sending_json)
          if (!data.transaction_id) {
              console.log("Error!", data.description)
              return null
          }
         
          return data.transaction_id
      } catch (error) {
          console.log(error)
          return null;
      }
  }
  