import axios from "../axios";
import placeholder from "../img/imagePlaceholder.png"

export const getBurnStatus = async (telegram_id, wallet_address, drop_id) => {
    const sending_json = {
        telegram_id: telegram_id,
        wallet_address: wallet_address,
        drop_id: drop_id
    }
    try {
        const {data} = await axios.post(`/api/user_info/`, sending_json)
        if (data.status_drop) {
            return data.status_drop
        }
        console.log("Error:",data.description);
        
        return null
       
    } catch (error) {        
        console.log(error)
        return null
        
    }

}

export const getAuthorNfts = async (wallet_address, drop_id) => {
    const sending_json = {
        wallet_address: wallet_address,
        drop_id: drop_id
    }
    try {
        const {data} = await axios.post(`/api/authors_nfts/ `, sending_json)
        if (data.status === "success") {
            return data.drop_info
        }
        console.log("Error:",data.description);
        
        return null
       
    } catch (error) {        
        console.log(error)
        return null
        
    }

}

export const sendBurnedNfts = async (burnedNfts) => {
    const sending_json = {
        burned_nfts: burnedNfts
    }
    try {
        const {data} = await axios.post(`/api/nft_success/ `, sending_json)
        if (data.status === "success") {
            return "success"
        }
        console.log("Error:",data.description);
        
        return "error"
       
    } catch (error) {        
        console.log(error)
        return "error"
        
    }

}



export const addBurnTransaction = async (transaction_hash, wallet_address) => {
    try {
        const sending_json = {
            transaction_hash: transaction_hash,
            wallet_address: wallet_address,
        }
        console.log("addBurnTransaction sending_json", sending_json)
        const {data} = await axios.post(`/api/burn_add_transaction/`, sending_json)
        if (data.transaction_id) {
            return data.transaction_id
        }

        return null

    } catch (error) {
        console.log(error)
        return null;
    }
}