import { useState } from "react";
import { getPrice } from "../../utils/dropConnections";
import tonToUSD from "../../utils/tonToUSD";



export const PtcToBurnInput = ({ptcToBurn, setPtcToBurn, setComission, setComissionToUSD}) => {
    const [timeoutId, setTimeoutId] = useState(null);

    const handleChange = (e) => {
        const value = e.target.value;
        setPtcToBurn(value);

        // Отменяем предыдущий таймер, если он существует
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Устанавливаем новый таймер
        const id = setTimeout(() => {
            if (value !== "" && /^\d*$/.test(value)) {
                sendPriceRequest(value);
            }
            else {
                setComission("")
                setComissionToUSD("")
            }
        }, 500); // 500 мс задержка после последнего ввода
        setTimeoutId(id);
    };

    const sendPriceRequest = async (nfts_cnt) => {
        const price = await getPrice(nfts_cnt);
        console.log('Price:', price);
        setComission(price)
        const comissionToUSD = await tonToUSD(price)
        setComissionToUSD(comissionToUSD)
        console.log("Comission:", comissionToUSD)        
       
    };

    return (
        <input
            className="form-input"
            type="text"
            name="ptc_to_burn"
            value={ptcToBurn}
            onChange={handleChange}
            required
            style={{ textAlign: 'center' }}
            pattern="\d*"
        />
    );
};